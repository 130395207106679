import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import MainPic from "../img/PagesVertes/Amenagements/Exterieur/Bois_pour_aménagements_exterieurs.jpg";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import {
  tileAccessoiresProps,
  tileFabricationMeublesEtAmenagementsProps,
  tileForTerrasseEtBardage,
} from "../propsTiles/propsTiles";
import { RoundedGreenContainer } from "./commonComponents";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 30%;
  }

  h2 {
    margin-top: 2em;
  }
`;

const amenagementExterieurContent: React.ReactNode = (
  <>
    <p>
      Nous offrons une large gamme de produits en bois pour transformer vos
      espaces extérieurs en lieux accueillants et esthétiques. Notre engagement
      qualité vous garantit des projets réussis.
    </p>
    <h1>NOS PRODUITS</h1>
    <RowBois>
      <div className="boisContainer">
        <h2>Bois pour terrasses et bardages</h2>
        <p>Visitez notre rubrique dédiée :</p>
      </div>
      <div className="boisContainer">
        <TilePageDetail {...tileForTerrasseEtBardage} />
      </div>
    </RowBois>
    <RowBois>
      <div className="boisContainer">
        <h2>Traverses paysagères</h2>
        <p>
          Nous fournissons de la traverse paysagère de qualité supérieure en
          chêne pour aménager vos différents espaces : délimitations de parking,
          délimitations de massifs, marches d'escalier extérieur, bordure de
          terrain de pétanque, ses utilisations sont nombreuses !
        </p>
        <h2>Bois pour clôtures, portillons et portails</h2>
        <p>
          Les clôtures en bois ajoutent de la sécurité et de l'intimité à vos
          espaces extérieurs tout en améliorant leur esthétique. En bois massif,
          nous sommes en mesure de vous fournir l'ensemble des bois pour créer
          vos envies. Poteaux rainurés en pin trainté autoclave, lames écran,
          lames de chalet, connecteurs métaliques.. Nous fabriquons et vendons
          tout le nécessaire à vos aménagements extérieurs ! Nous pouvons
          également vous fournir des produits finis fabriqués en usine, tels que
          des portillons en acacia, portails en bois exotique, etc.
        </p>
      </div>
    </RowBois>
    <RowBois>
      <div className="boisContainer">
        <h2>Bois pour Pergolas</h2>
        <p>
          Les pergolas en bois apportent de l'ombre et du style à vos jardins et
          terrasses, créant des espaces de détente parfaits.
        </p>
        <p>
          Bois de pin traité : Traité pour résister aux éléments, une option
          économique et durable.
        </p>
        <p>
          Douglas : Naturellement imputrécible et durable, c'est un excellent
          rapport qualité prix pour vos aménagements extérieurs.
        </p>
        <p>
          Bois exotique : Pour une touche de luxe et une durabilité
          exceptionnelle.
        </p>
      </div>
      <div className="boisContainer">
        <h2>Bois pour aménagements divers</h2>
        <p>
          Pour tous vos autres projets d’aménagement extérieur, nous offrons des
          bois adaptés à vos besoins spécifiques.
        </p>
        <p>
          Bois pour abris de jardin : Solides et esthétiques, parfaits pour vos
          espaces de rangement extérieurs.
        </p>
        <p>
          Bois pour bancs et mobilier d’extérieur : Durables et élégants, pour
          des meubles faits pour durer.
        </p>
        <p>
          Bois pour jeux d’enfants : Traités et sûrs, idéaux pour les structures
          de jeux extérieurs.
        </p>
      </div>
    </RowBois>

    <h1>AVANTAGE DE NOS PRODUITS EN BOIS</h1>

    <RoundedGreenContainer>
      <h2>Qualité supérieure : </h2>
      <p>
        Nos bois sont sélectionnés pour leur robustesse et leur résistance aux
        éléments.
      </p>
      <h2>Éco-responsabilité : </h2>
      <p>Bois issus de forêts gérées durablement, certifiés FSC et PEFC.</p>
      <h2>Durabilité : </h2>
      <p>
        Bois traités et certifiés pour une longue durée de vie, même en
        extérieur.
      </p>
      <h2>Esthétique : </h2>
      <p>
        Large choix de teintes et de finitions pour s’adapter à tous les styles
        de jardins.
      </p>
    </RoundedGreenContainer>
    <p>
      Envie de solutions sur mesure, meubles et aménagements intérieurs ?
      Visitez notre site Maison Charlet
    </p>
    <TilePageDetail {...tileFabricationMeublesEtAmenagementsProps} />
    <p>
      Découvrez tous nos accessoires sélectionnés spécialement par nos soins
      pour leur qualité
    </p>
    <TilePageDetail {...tileAccessoiresProps} />
  </>
);

export const propsBoisForAmenagementsExterieurs: PageVerteCoreProps = {
  content: amenagementExterieurContent,
  imageTitre: MainPic,
  titre: "BOIS POUR AMÉNAGEMENT EXTÉRIEURS",
};
