import { styled } from "styled-components";
import { BoisScieEnFrance } from "./BoisScieEnFrance";
import { ForetCertifiees } from "./ForetCertifiees";
import React from "react";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap:4em;
`;

const ScieAndCertif: React.FC = () => {
  return (
    <>
      <Container>
        <BoisScieEnFrance></BoisScieEnFrance>
        <ForetCertifiees></ForetCertifiees>
      </Container>
    </>
  );
};

export default ScieAndCertif;
