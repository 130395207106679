import React, { useState } from "react";
import styled from "styled-components";
import './WelcomePageCarousel.css'
// Interface pour définir les types des props du carousel
interface CarouselProps {
  images: string[];
}

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100vw;
  overflow: hidden;
`;

const ImageContainer = styled.div<{ translateX: number }>`
  display: flex;
  transform: translateX(${(props) => props.translateX}%);
  transition: transform 0.5s ease-in-out;
`;

const Image = styled.img`
   width: 100vw;
   height: 100%;
   object-fit: cover;
`;

const ArrowButton = styled.button<{ left?: boolean }>`
  position: absolute;
  top: 50%;
  ${(props) => (props.left ? "left: 10px;" : "right: 10px;")}
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
`;

const WelcomePageCarousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <CarouselContainer className="carousel">
        <ArrowButton left onClick={handlePrev}>
          ◀
        </ArrowButton>
        <ImageContainer translateX={-currentIndex * 100/images.length}>
          {images.map((image, index) => (
            <Image key={index} src={image} alt={`Carousel ${index}`} />
          ))}
        </ImageContainer>
        <ArrowButton onClick={handleNext}>▶</ArrowButton>
        <div className="carouselTitle">
        <h1>VENTE,</h1><h1>NÉGOCE</h1>
        <h2>& FABRICATIONS</h2><h2> SUR MESURE</h2>
        <h3>POUR LES PROFESSIONNELS ET PARTICULIERS</h3>
        </div>
      </CarouselContainer>
    </>
  );
};

export default WelcomePageCarousel;
