import { PageResumeProps } from "../components/PageResume";
import tileImage1 from "../img/TuilesPagesRouges/Industrie/couverture_industrie.jpg";
import bottomImage from "../img/TuilesPagesRouges/Industrie/panneaux_industrie_charlet.jpg";
import { tileAccessoiresProps, tileBoisPourIndustrieProps, tileCaisseEmballageProps, tileDallesProps, tileFabricationsSpecifiquesProps, tileUsinagesSpecifiquesProps } from "../propsTiles/propsTiles";

export const industrieProps: PageResumeProps = {
  titre: "Industrie",
  imageTitre: tileImage1,
  firstParagraph: `Nous sommes spécialistes du négoce et de la transformation de bois  et de panneaux pour des applications industrielles avec un stock de plus de 15 000 m², situé près de Lille.

Bois de coffrage, Bois de qualité charpente ou menuiserie, prototypage en bois dur (Chêne, Hêtre, Bois exotique), panneaux en bois petits et grands formats etc.

Nos ateliers sont capables de travailler l'ensemble de nos produits afin de répondre à vos besoins les plus spécifiques : bois coupés à longueur, délignés, rabotés, moulurés, débit de panneaux, gabarits de cintrage, etc`,
  tile1: tileBoisPourIndustrieProps,
  tile2: tileDallesProps,
  tile3: tileUsinagesSpecifiquesProps,
  tile4: tileFabricationsSpecifiquesProps,
  tile5: tileAccessoiresProps,
  tile6: tileCaisseEmballageProps,
  bottomImage: bottomImage,
  secondParagraph: `Notre société possède une grande expérience dans la fabrication d'éléments spécifiques pour du prototypage pré-série aux applications industrielles, ou encore dans la fabrication de caisses et d'emballages pour vos machines industrielles, directement dans notre atelier de 3500m² situé à côté de Lille.`,
  thirdParagraph: `Nous fournissons également l'ensemble des accessoires nécessaires à toutes vos réalisations (visserie, plots, sabots, colle, etc.)`,
};
