import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import PageResume from "./components/PageResume";
import { coffrageProps } from "./propsForPageRouges/propsForPagesCoffrage";
import { charpenteProps } from "./propsForPageRouges/propsForPageCharpente";
import { industrieProps } from "./propsForPageRouges/propsForPageIndustrie";
import LinksEnum from "./routes/routesReference";
import { amenagementsInterieursProps } from "./propsForPageRouges/propsForAmenagementsInterieurs";
import { amenagementsExterieursProps } from "./propsForPageRouges/propsForAmenagementsExterieurs";
import PageVerteCore from "./components/PageVerteCore";
import { propsForBoisDeCoffrage } from "./propsForPagesVertes/propsForBoisDeCoffrage";
import { propsForBoisDeCharpente } from "./propsForPagesVertes/propsForBoisDeCharpente";
import { propsForIndustrie } from "./propsForPagesVertes/boisPourIndustrieProps";
import { propsForPanneauxFilmes } from "./propsForPagesVertes/propsForPanneauFilmes";
import { propsForDallesNonFilmees } from "./propsForPagesVertes/propsDallesNonFilmes";
import { propsForUsinagesSpecifiques } from "./propsForPagesVertes/propsForUsinagesSpecifiques";
import { propsForAmenagementInterieur } from "./propsForPagesVertes/propsBoisPourAmenagementsInterieurs";
import { propsBoisForAmenagementsExterieurs } from "./propsForPagesVertes/propsBoisPourAmenagementsExterieurs";
import SawAnimation from "./components/SawAnimation";
import { propsFabricationCoffrage } from "./propsForPagesVertes/propsFabricationCoffrageEtMannequins";
import { propsFabricationSpecifique } from "./propsForPagesVertes/propsFabricationSpecifiques";
import { propsForParquet } from "./propsForPagesVertes/propsParquet";
import { propsForTerrasseEtBardage } from "./propsForPagesVertes/propsForTerrasseEtBardage";
import { propsForOssature } from "./propsForPagesVertes/propsForOssature";
import { propsForCaisseEmballade } from "./propsForPagesVertes/propsForCaisseEmballage";
import { propsForAccessoires } from "./propsForPagesVertes/propsForAccessoires";
import ContactForm from "./components/ContactForm";
import MentionsLegales from "./components/MentionsLegales";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/mentions-legales",
    element: <MentionsLegales />,
  },
  {
    path: "/vente-bois-auxparticuliers.php",
    element: <Root />,
  },
  {
    path: "/bois-panneaux.php",
    element: <Root />,
  },
  {
    path: "/vente-bois-auxparticuliers.php",
    element: <PageVerteCore {...propsForAmenagementInterieur} />,
  },
  {
    path: "/coffrages11.php",
    element: <PageVerteCore {...propsFabricationCoffrage} />,
  },
  {
    path: "/bardages.php",
    element: <PageVerteCore {...propsForTerrasseEtBardage} />,
  },
  {
    path: "/fabricationsur-mesure.php",
    element: <PageVerteCore {...propsFabricationSpecifique} />,
  },
  {
    path: "/terrasses.php",
    element: <PageVerteCore {...propsForTerrasseEtBardage} />,
  },
  {
    path: "/ossature-bois.php",
    element: <PageVerteCore {...propsForOssature} />,
  },
  {
    path: LinksEnum.Coffrage,
    element: <PageResume {...coffrageProps} />,
  },
  {
    path: LinksEnum.Charpente,
    element: <PageResume {...charpenteProps} />,
  },
  {
    path: LinksEnum.Industrie,
    element: <PageResume {...industrieProps} />,
  },
  {
    path: LinksEnum.AmenagementsInterieurs,
    element: <PageResume {...amenagementsInterieursProps} />,
  },
  {
    path: LinksEnum.AmenagementsExterieurs,
    element: <PageResume {...amenagementsExterieursProps} />,
  },
  {
    path: LinksEnum.BoisDeCoffrage,
    element: <PageVerteCore {...propsForBoisDeCoffrage} />,
  },
  {
    path: LinksEnum.BoisDeCharpente,
    element: <PageVerteCore {...propsForBoisDeCharpente} />,
  },
  {
    path: LinksEnum.BoisPourIndustrie,
    element: <PageVerteCore {...propsForIndustrie} />,
  },
  {
    path: LinksEnum.PanneauxFilmes,
    element: <PageVerteCore {...propsForPanneauxFilmes} />,
  },
  {
    path: LinksEnum.DallesEtPanneauxNonFilmes,
    element: <PageVerteCore {...propsForDallesNonFilmees} />,
  },
  {
    path: LinksEnum.UsinagesSpecifiques,
    element: <PageVerteCore {...propsForUsinagesSpecifiques} />,
  },
  {
    path: LinksEnum.BoisPourAmenagementsInterieurs,
    element: <PageVerteCore {...propsForAmenagementInterieur} />,
  },
  {
    path: LinksEnum.BoisPourAmenagementsExterieurs,
    element: <PageVerteCore {...propsBoisForAmenagementsExterieurs} />,
  },
  {
    path: LinksEnum.FabricationCoffrageEtMannequins,
    element: <PageVerteCore {...propsFabricationCoffrage} />,
  },
  {
    path: LinksEnum.FabricationsSpecifiques,
    element: <PageVerteCore {...propsFabricationSpecifique} />,
  },
  {
    path: LinksEnum.Parquet,
    element: <PageVerteCore {...propsForParquet} />,
  },
  {
    path: LinksEnum.TerrasseBardage,
    element: <PageVerteCore {...propsForTerrasseEtBardage} />,
  },
  {
    path: LinksEnum.OssatureBois,
    element: <PageVerteCore {...propsForOssature} />,
  },
  {
    path: LinksEnum.CaisseEmballage,
    element: <PageVerteCore {...propsForCaisseEmballade} />,
  },
  {
    path: LinksEnum.Accessoires,
    element: <PageVerteCore {...propsForAccessoires} />,
  },
  {
    path: LinksEnum.Contact,
    element: <ContactForm />,
  },
  {
    path: "*",
    element: <SawAnimation />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
