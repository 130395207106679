import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import mainPic from "../img/PagesVertes/Usinages/Usinages_specifiques.jpg";
import rubans from "../img/PagesVertes/Usinages/Machine_à_rubans.jpg";
import pied from "../img/PagesVertes/Usinages/pied_usinage.jpg";
import moulure from "../img/PagesVertes/Usinages/moulure.jpg";
import rabot from "../img/PagesVertes/Usinages/bois_raboté.jpg";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import { tileAccessoiresProps } from "../propsTiles/propsTiles";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 33%;
  }

  .imgUsinage {
    width: 33%;
    margin: 15px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    .imgUsinage {
      width: 100%;
      margin: 5px;
    }
  }
`;
const usinagesContent: React.ReactNode = (
  <>
    <p>
      Nous proposons des usinages spécifiques sur l’ensemble de nos panneaux en
      bois ainsi que sur la plupart de nos bois de coffrage, bois de qualité
      charpente ou menuiserie afin de réaliser des prototypages en bois dur
      (Chêne, Hêtre, Bois exotique)
    </p>
    <p>
      Nous travaillons régulièrement à la demande des bois comme le Douglas, le
      Meleze, l’Ipé, le Sipo, l'Itauba, l'Okan, le Jatoba, l'Iroko, etc pour des
      besoins plus exigeants.
    </p>
    <p>
      Nos ateliers sont capables de travailler l'ensemble de nos produits afin
      de répondre à vos besoins les plus spécifiques :
    </p>
    <p>Bois coupés à longueur, délignés, rabotés, moulurés</p>
    <p>Débit de panneaux, gabarits de cintrage, etc.</p>
    <RowBois>
      <img className="imgUsinage" src={moulure} alt=""></img>
      <img className="imgUsinage" src={rabot} alt="" />
      <img className="imgUsinage" src={pied} alt="" />
      <img className="imgUsinage" src={rubans} alt="" />
    </RowBois>
    <p>
      Découvrez tous nos accessoires sélectionnés spécialement par nos soins
      pour leur qualité
    </p>
    <TilePageDetail {...tileAccessoiresProps} />
  </>
);

export const propsForUsinagesSpecifiques: PageVerteCoreProps = {
  content: usinagesContent,
  imageTitre: mainPic,
  titre: "USINAGES SPÉCIFIQUES",
};
