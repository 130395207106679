import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import panneauxFilmes from "../img/PagesVertes/Panneaux/Panneaux_filmés.jpg";
import schemaPanneau from "../img/PagesVertes/Panneaux/schéma_panneaux_filmés.jpg";
import tableauPanneau from "../img/PagesVertes/Panneaux/Tableau_panneaux_filmés.png";
import TilePageDetail from "../components/TilePageDetail";
import {
  tileAccessoiresProps
} from "../propsTiles/propsTiles";


const panneauxFilmesContent: React.ReactNode = (
  <>
    <p>
      Nous sommes spécialistes du négoce de bois et de panneaux de coffrage
      (panneaux filmés / bakélisés) en particulier pour l'industrie du BTP avec
      un stock de plus de 15 000 m², situé près de Lille. <br />
      <br /> Nous proposons des débits de panneaux, gabarits de cintrage, etc.
      Consultez nos différents articles disponibles ou prenez directement
      contact avec notre équipe.
    </p>
    <h2>Un panneau filmé, c’est quoi ?</h2>
    <img src={schemaPanneau} alt=""></img>
    <p>
      Notre gamme de panneaux est très vaste, cependant nous pouvons catégoriser
      différentes familles de panneaux comme ici :
    </p>
    <img src={tableauPanneau} alt=""></img>
    <p>
      Découvrez tous nos accessoires sélectionnés spécialement par nos soins
      pour leur qualité :
    </p>
    <TilePageDetail {...tileAccessoiresProps} />
  </>
);

export const propsForPanneauxFilmes: PageVerteCoreProps = {
  content: panneauxFilmesContent,
  imageTitre: panneauxFilmes,
  titre: "PANNEAUX FILMÉS",
};
