import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import mainPic from "../img/PagesVertes/Ossature/Ossatures_bois.jpg";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import { tileAccessoiresProps } from "../propsTiles/propsTiles";
import { RoundedGreenContainer } from "./commonComponents";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;
  gap:1.5em;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ListeEssence = styled.div``;
const industrieContent: React.ReactNode = (
  <>
    <h2>
      Des solutions d'emballage robustes et personnalisées pour tous vos besoins
    </h2>
    <p>
      Avec des années d'expérience dans le domaine, nous offrons des solutions
      d'emballage robustes et personnalisées pour protéger vos produits lors du
      transport et du stockage. Notre engagement envers la qualité et la
      satisfaction client nous distingue sur le marché.
    </p>
    <h1>NOS PRODUITS</h1>
    <p>
      Nous proposons une large gamme de caisses d'emballage adaptées à diverses
      industries et besoins spécifiques. Voici un aperçu de nos principales
      catégories de produits :
    </p>
    <h2>1. CAISSES EN BOIS STANDARD</h2>
    <p>
      Des solutions polyvalentes et économiques pour l'emballage et le transport
      de marchandises diverses.
    </p>
    <ListeEssence>
      <p>
        <b>Caisses pleines </b> : Pour une protection maximale contre les chocs
        et les intempéries.
      </p>
      <p>
        <b>Caisses ajourées </b> : Idéales pour les produits nécessitant une
        ventilation.
      </p>
      <p>
        <b>Caisses palettes </b> : Combinant les avantages des palettes et des
        caisses pour un transport facile et sécurisé.
      </p>
    </ListeEssence>
    <h2>2. CAISSES SUR MESURE</h2>
    <ListeEssence>
      <p>
        <b>Caisses de grande dimension </b> : Pour les équipements industriels
        volumineux.
      </p>
      <p>
        <b>Caisses renforcées </b> : Pour les articles lourds ou fragiles
        nécessitant une protection supplémentaire.
      </p>
      <p>
        <b>Caisses compartimentées </b> : Pour le transport sécurisé de
        plusieurs articles dans une même caisse.
      </p>
    </ListeEssence>
    <h2>3. CAISSES CERTIFIÉES</h2>
    <p>
      Pour les expéditions internationales nécessitant des normes de conformité
      spécifiques.
    </p>
    <ListeEssence>
      <p>
        <b>Caisses certifiées NIMP 15 </b> : Conformes aux normes
        internationales de protection contre les parasites du bois.
      </p>
    </ListeEssence>
    <h1>NOTRE PROCESSUS DE FABRICATION</h1>
    <p>
      Notre processus de fabrication garantit des caisses d'emballage de haute
      qualité, robustes et adaptées à vos besoins spécifiques.
    </p>
    <h1>1. ÉTUDE ET CONCEPTION</h1>
    <p>
      Chaque projet commence par une analyse détaillée de vos besoins. Notre
      équipe de conception travaille en étroite collaboration avec vous pour
      développer des solutions d'emballage efficaces et économiques.
    </p>
    <ListeEssence>
      <p>
        <b>Analyse des produits </b> : Évaluation des dimensions, du poids et
        des exigences de protection.
      </p>
      <p>
        <b>Conception personnalisée </b> : Plans détaillés et modélisation 3D
        pour des solutions sur mesure.
      </p>
    </ListeEssence>
    <h1>2. SÉLECTION DES MATÉRIAUX</h1>
    <p>
      Nous utilisons des bois de haute qualité, provenant de sources durables et
      certifiées.
    </p>
    <ListeEssence>
      <p>
        <b>Bois résineux </b> : Léger et robuste, idéal pour les caisses
        standards.
      </p>
      <p>
        <b>Bois dur </b> : Pour les caisses nécessitant une résistance accrue.
      </p>
      <p>
        <b>Panneaux contreplaqué ou OSB </b> : Pour des solutions d'emballage
        spécifiques.
      </p>
    </ListeEssence>
    <h1>3. FABRICATION EN ATELIER</h1>
    <p>
      Notre atelier est équipé de machines de pointe pour assurer une
      fabrication précise et efficace.
    </p>
    <ListeEssence>
      <p>
        <b>Découpe et assemblage </b> : Utilisation de technologies avancées
        pour une précision optimale.
      </p>
      <p>
        <b>Renforcements </b> : Ajout de renforts métalliques ou en bois pour
        une solidité accrue.
      </p>
      <p>
        <b>Contrôle qualité </b> : Chaque caisse est rigoureusement contrôlée
        pour garantir sa conformité aux normes de qualité.
      </p>
    </ListeEssence>
    <h1>4. PERSONNALISATION ET MARQUAGE</h1>
    <p>
      Nous offrons des options de personnalisation pour répondre à vos besoins
      spécifiques en matière de branding et de traçabilité.
    </p>
    <h2>Marquage :</h2>
    <p>
      Impression du logo, des instructions de manipulation et des codes-barres.
    </p>
    <h2>Personnalisation des dimensions :</h2>
    <p> Fabrication sur mesure pour s'adapter parfaitement à vos produits.</p>
    <RoundedGreenContainer>
      <h1>AVANTAGES DE NOS CAISSES D'EMBALLAGE</h1>
      <RowBois>
        <div className="boisContainer">
          <h2>Robustesse : </h2>
          <p>
            Conçues pour résister aux conditions de transport les plus
            rigoureuses.
          </p>

          <div className="boisContainer">
            <h2>Personnalisation: </h2>
            <p>Solutions sur mesure pour répondre à vos besoins spécifiques.</p>
          </div>
        </div>
      </RowBois>
      <RowBois>
        <div className="boisContainer">
          <h2>Conformité : </h2>
          <p>Caisses conformes aux normes internationales de transport.</p>
        </div>
        <div className="boisContainer">
          <h2>Respect de l'environnement :</h2>
          <p>
            Utilisation de bois certifié provenant de forêts gérées durablement.
          </p>
        </div>
      </RowBois>
    </RoundedGreenContainer>
    <h1>APPLICATIONS COURANTES</h1>
    <p>
      Nos caisses d'emballage sont utilisées dans de nombreux secteurs,
      notamment :
    </p>
    <ListeEssence>
      <p>
        <b>Industrie manufacturière </b> : Transport de machines et équipements.
      </p>
      <p>
        <b>Aéronautique et spatial </b> : Emballage sécurisé pour composants
        sensibles.
      </p>
      <p>
        <b>Agroalimentaire</b> : Caisses pour produits alimentaires nécessitant
        une protection spécifique.
      </p>
      <p>
        <b>Pharmaceutique</b> : Solutions d'emballage pour produits médicaux et
        pharmaceutiques.
      </p>
    </ListeEssence>
    <p>
      Découvrez tous nos accessoires sélectionnés spécialement par nos soins
      pour leur qualité
    </p>
    <TilePageDetail {...tileAccessoiresProps} />
  </>
);

export const propsForCaisseEmballade: PageVerteCoreProps = {
  content: industrieContent,
  imageTitre: mainPic,
  titre: "CAISSES D'EMBALLAGE",
};
