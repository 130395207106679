import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import mainPic from "../img/PagesVertes/Ossature/Ossatures_bois.jpg";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import { tileAccessoiresProps } from "../propsTiles/propsTiles";
import { RoundedGreenContainer } from "./commonComponents";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
  }

   width:100%;
   flex-wrap:wrap;
   justify-content: center;
   gap:5%;
`;

const ListeEssence = styled.div``;
const industrieContent: React.ReactNode = (
  <>
    <h1>NOTRE PROCESSUS DE FABRICATION</h1>
    <p>
      La fabrication d'ossature bois en atelier permet d’assurer un contrôle
      optimal de la qualité, tout en réduisant les délais et les coûts de
      construction sur site. Voici un aperçu de notre processus de fabrication :
    </p>
    <p>
      Un agrandissement de terrasse ou de bardage à effectuer dans quelques
      années ? Nous pourrons vous proposer exactement le même produit que celui
      déjà acheté chez nous.
    </p>
    <h2>1. ETUDE ET CONCEPTION : REALISÉE CHEZ UN DE NOS PARTENAIRES</h2>
    <p>
      Chaque projet commence par une étude approfondie et une conception
      personnalisée. Ingénieurs et architectes travaillent en étroite
      collaboration pour concevoir des structures qui répondent à vos besoins
      spécifiques.
    </p>
    <ListeEssence>
      <p>
        <b>Analyse des besoins </b> : Identification des exigences du projet.
      </p>
      <p>
        <b>Conception 3D </b> : Modélisation précise des éléments d’ossature.
      </p>
      <p>
        <b>Calculs structurels </b> : Vérification de la résistance et de la
        stabilité.
      </p>
    </ListeEssence>
    <h2>2. SÉLECTION DES MATÉRIAUX</h2>
    <p>
      Nous utilisons du bois de première qualité, issu de forêts gérées
      durablement et certifié FSC ou PEFC. Les essences de bois les plus
      couramment utilisées incluent :
    </p>
    <ListeEssence>
      <p>
        <b>Épicéa </b> : Léger et résistant, idéal pour les structures
        porteuses.
      </p>
      <p>
        <b>Douglas </b> : Naturellement durable et résistant aux intempéries.
      </p>
      <p>
        <b>Pin </b> : Traité pour une durabilité accrue, parfait pour les
        environnements humides.
      </p>
    </ListeEssence>
    <h2>3. FABRICATION EN ATELIER</h2>
    <p>
      Nos ateliers sont équipés de technologies de pointe pour assurer une
      fabrication précise et efficace des éléments d'ossature bois.
    </p>
    <ListeEssence>
      <p>
        <b>Découpe numérique </b> : Utilisation de machines CNC, si besoin.
      </p>
      <p>
        <b>Assemblage </b> : Les éléments sont assemblés avec des connecteurs
        métalliques de haute qualité.
      </p>
      <p>
        <b>Contrôle qualité </b> : Chaque pièce est minutieusement vérifiée pour
        garantir sa conformité aux normes de sécurité et de performance.
      </p>
    </ListeEssence>
    <h1>4. PRÉPARATION ET EXPÉDITION</h1>
    <p>
      Une fois les éléments fabriqués et contrôlés, ils sont préparés pour
      l’expédition sur site.
    </p>
    <ListeEssence>
      <p>
        <b>Pré-assemblage </b> : Certains éléments peuvent être pré-assemblés en
        atelier pour réduire le temps d'installation sur site.
      </p>
      <p>
        <b>Conditionnement </b> : Les pièces sont soigneusement emballées pour
        protéger le bois pendant le transport.
      </p>
      <p>
        <b>Logistique </b> : Organisation du transport et de la livraison sur
        site.
      </p>
    </ListeEssence>
    <RoundedGreenContainer>
      <h1>AVANTAGES DE NOTRE MÉTHODE</h1>
      <RowBois className="rowAvantages">
        <div className="boisContainer">
          <h2>Qualité supérieure: </h2>
          <p>
            Fabrication en environnement contrôlé pour une précision et une
            qualité optimales.
          </p>
        </div>
        <div className="boisContainer">
          <h2>Économie de coûts: </h2>
          <p>Moins de main-d'œuvre et de temps de chantier nécessaires.</p>
        </div>
        <div className="boisContainer">
          <h2>Réduction des délais:</h2>
          <p>
            Pré-assemblage en atelier permettant une installation rapide sur
            site.
          </p>
        </div>
        <div className="boisContainer">
          <h2>Durabilité: </h2>
          <p>
            Utilisation de bois certifiés et de techniques de fabrication
            respectueuses de l'environnement.
          </p>
        </div>
      </RowBois>
    </RoundedGreenContainer>
    <h2>APPLICATION :</h2>
    <p>
      Nos ossatures bois sont adaptées à une variété de projets, notamment :
    </p>
    <ListeEssence>
      <p>
        <b>Maisons individuelles </b> : Structures robustes et durables pour des
        habitations confortables.
      </p>
      <p>
        <b>Bâtiments commerciaux </b> : Solutions flexibles et modulaires pour
        répondre aux besoins spécifiques des entreprises.
      </p>
      <p>
        <b>Extensions et rénovations </b> : Ossatures légères et résistantes,
        idéales pour les agrandissements et les améliorations structurelles.
      </p>
    </ListeEssence>
    <p>
      Découvrez tous nos accessoires sélectionnés spécialement par nos soins
      pour leur qualité
    </p>
    <TilePageDetail {...tileAccessoiresProps} />
  </>
);

export const propsForOssature: PageVerteCoreProps = {
  content: industrieContent,
  imageTitre: mainPic,
  titre: "OSSATURE BOIS",
};
