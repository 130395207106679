import React from "react";
import Header from "./Header";
import { styled } from "styled-components";
import ContactElement from "./ContactElement";
import Footer from "./Footer";

const ContactForm: React.FC = () => {
  const ContainerDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    font-family: "Montserrat", sans-serif;

    button {
      font-family: "Montserrat", sans-serif;

      background-color: #d7d7d7; /* Gris de base */
      color: black; /* Couleur du texte */
      border: none; /* Pas de bordure */
      border-radius: 30px; /* Coins arrondis */
      padding: 10px 20px; /* Espacement interne */
      font-size: 16px; /* Taille du texte */
      cursor: pointer; /* Changement de curseur au survol */
      transition: background-color 0.3s ease; /* Transition fluide */
    }

    button:hover {
      background-color: #8c8b8b; /* Couleur plus foncée au survol */
    }

    div textarea{
      resize: none
    }

    @media (min-width: 768px) {
      .ficheMap {
        width: 40%;
        padding-left: 5%;
        padding-right: 5%;
      }

      div input, div textarea {
        width: 100%;
      }
    }

    .contactForm {
      margin-top:2em; 
    }

    .contactFormContainer {
      padding-left: 3em;
      padding-right: 3em;
      display
    }

    @media (max-width: 768px) {
      .ficheMap {
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 2em;
        max-width: 100vw;
        margin-bottom: 22em;
      }

      div input, div textarea {
        width: 100%;
        margin-left: 0.8em;
      }

      .containingDiv {
        width: 85%;
      }
    }

    #agreeToTerms {
      width: 20px;
    }

    .rowCheckbox {
      display: flex;
      flex-direction: row;
      justify-content: left;
      text-align: left;
    }

    .phoneIcon {
      color: #000;
    }

    .mapGoogle {
      width: 100%;
      height: 500px;
      border: none;
      overflow: hidden;
    }
  `;

  const GoogleMap: React.FC = React.memo(() => {
    return (
      <iframe
        title="Google Maps - Charlet Bois"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2528.8607520193996!2d3.0554056999999997!3d50.666845900000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c32a586b976a51%3A0xc33336b0cd6591ae!2sCharlet%20Bois!5e0!3m2!1sfr!2sfr!4v1727714601060!5m2!1sfr!2sfr"
        className="mapGoogle"
        allowFullScreen={true}
        loading="lazy"
      />
    );
  });

  return (
    <>
      <Header />
      <ContainerDiv>
        {/* Première div : Google Maps iframe */}
        <div className="contactFormContainer">
          <h1>INFORMATIONS</h1>
          <p>
            ✉︎ Email:{" "}
            <a href="mailto:accueil@charlet-bois.com">
              accueil@charlet-bois.com
            </a>
          </p>
          <p>☎︎ Téléphone: 03 28 38 83 83</p>
          <div className="contactForm">
            <h1>FORMULAIRE DE CONTACT</h1>
            <ContactElement />
          </div>
        </div>
        <div className="ficheMap">
          <h1>CONTACT / DEVIS</h1>
          <p>
            Pour toute demande de renseignements ou suggestions sur notre site,
            n'hésitez pas à nous contacter, nous vous répondrons dans les plus
            brefs délais.
          </p>
          <p>
            Nous privilégions l'acceuil des particuliers les lundi après-midi,
            mercredi après-midi et vendredi après-midi. Merci pour votre
            compréhension.
          </p>
          <GoogleMap></GoogleMap>
        </div>
      </ContainerDiv>
      <Footer />
    </>
  );
};

export default ContactForm;
