import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import BoisDeCharpente from "../img/PagesVertes/Charpente/Bois_de_charpente.jpg";
import ScieAndCertif from "../components/ScieAndCertif";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import {
  tileAccessoiresProps,
  tileOssatureBois,
  tileUsinagesSpecifiquesProps,
} from "../propsTiles/propsTiles";

const StyledDiv = styled.div`
  table {
    width: 80%;
  }

  @media (min-width: 768px) {
    th {
      font-size: 1em;
    }
  }

  @media (max-width: 768px) {
    th {
      font-size: 0.8em;
    }
  }
`;

const StyledTable = styled.table`
  table,
  th,
  td {
    border: 1px solid black; /* Lignes noires pour le tableau, les cellules et les headers */
  }
  th {
    font-weight: bold; /* Header en gras */
    padding: 10px; /* Espacement pour un peu plus de lisibilité */
  }

  td {
    padding: 10px; /* Espacement pour les cellules du corps du tableau */
  }
`;

const DivRowImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 5em;
  align-items: center;
  justify-content: center;
`;

const DivImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    width: 33%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const coffrageContent: React.ReactNode = (
  <>
    <p>
      Les bois de charpente, couverture et ossature bois sont des bois dont la
      qualité, normée, permet la création de structures ou de charpentes
      durables dans des projets de construction. Tous nos bois peuvent être
      traités par nos soins pour les rendre de classe II, et ainsi être propre à
      l'usage dans les structures des différentes constructions.
    </p>
    <ScieAndCertif></ScieAndCertif>
    <p>Sections et dénominations standards en bois de charpente :</p>
    <StyledDiv>
      <StyledTable>
        <tr>
          <th>DÉNOMINATION</th>
          <th>ÉPAISSEURS</th>
          <th>LARGEURS</th>
        </tr>
        <tr>
          <td>VOLIGE</td>
          <td>18</td>
          <td>100</td>
        </tr>
        <tr>
          <td>PLANCHE</td>
          <td>32</td>
          <td>175</td>
        </tr>
        <tr>
          <td>PLANCHE</td>
          <td>38</td>
          <td>225</td>
        </tr>
        <tr>
          <td>DEMI-CHEVRON</td>
          <td>63</td>
          <td>40</td>
        </tr>
        <tr>
          <td>CHEVRON</td>
          <td>63</td>
          <td>87</td>
        </tr>
        <tr>
          <td>BASTAING</td>
          <td>63</td>
          <td>175</td>
        </tr>
        <tr>
          <td>MADRIER</td>
          <td>75</td>
          <td>225</td>
        </tr>
        <tr>
          <td>POUTRE</td>
          <td>100</td>
          <td>300</td>
        </tr>
      </StyledTable>
    </StyledDiv>
    <p>Sections et dénominations standards en bois d’ossature :</p>
    <StyledTable>
      <tr>
        <th>EPAISSEURS</th>
        <th>LARGEURS</th>
      </tr>
      <tr>
        <td>19</td>
        <td>38</td>
      </tr>
      <tr>
        <td>25</td>
        <td>32</td>
      </tr>
      <tr>
        <td>25</td>
        <td>38</td>
      </tr>
      <tr>
        <td>38</td>
        <td>38</td>
      </tr>
    </StyledTable>
    <p>
      Nous proposons pour compléter votre besoin des planches de rive en sapin
      du nord de section 28x170 et 28x220, ainsi que des planches de fonds de
      section 35x220 <br />
      <br />
      Des moulures de rives sont également disponibles en section 40x60
    </p>
    <p>
      La section la plus employée en bois d'ossature est la 45 x 145. Nous
      proposons également cette section en bois traités autoclave afin de
      réaliser la lisse basse de vos constructions.
    </p>
    <h3>
      Notre atelier de 3500 m² nous permet de fabriquer vos éléments en ossature
      bois, gain de temps et d'argent, consultez notre page dédiée :
    </h3>
    <TilePageDetail {...tileOssatureBois} />
    <DivRowImages>
      <DivImageContainer>
        <p>
          Chacune de ses sections peut être retravaillée de façon à répondre à
          vos besoins plus spécifiques.
        </p>
        <TilePageDetail {...tileUsinagesSpecifiquesProps} />
      </DivImageContainer>
      <DivImageContainer>
        <p>
          Découvrez tous nos accessoires sélectionnés spécialement par nos soins
          pour leur qualité
        </p>
        <TilePageDetail {...tileAccessoiresProps} />
      </DivImageContainer>
    </DivRowImages>
  </>
);

export const propsForBoisDeCharpente: PageVerteCoreProps = {
  content: coffrageContent,
  imageTitre: BoisDeCharpente,
  titre: "BOIS DE CHARPENTE",
};
