import SlidableMenu from "./SlidableMenu";
import logo from "../../src/img/phoneLogoBlack.png";
import logoCharletBois from "../../src/img/logoCharletBois.png";
import "./Header.css";
import { Link } from "react-router-dom";
import LinksEnum from "../routes/routesReference";
import ClicableMenuComponent from "./ClicableMenuComponent";

const Header = () => {
  const isHomePage = true;
  return (
    <>
      <header className="mainHeader">
        {isHomePage && (
          <>
            <Link to="/">
              <img
                src={logoCharletBois}
                className="logoHeader"
                alt="Logo Etablissements Albert Charlet, négoce et travail du bois"
              />
            </Link>
          </>
        )}
        <div className="quartDeCercle">
          <Link to={LinksEnum.Contact}>
            <a
              href="https://www.instagram.com/maison_charlet/"
              className="phoneLogo"
              style={{
                backgroundImage: `url(${logo})`,
              }}
              aria-label="Découvrez l'Instagram de Maison Charlet"
            ></a>
          </Link>
        </div>
        <SlidableMenu></SlidableMenu>
      </header>
      <div className="flexRowMenuWelcome">
        <Link to={LinksEnum.Coffrage} className="flexMenuItem">
          <div>COFFRAGE</div>
        </Link>
        <Link to={LinksEnum.Charpente} className="flexMenuItem">
          <div>
            CHARPENTE,<br></br> COUVERTURE & OSSATURE BOIS
          </div>
        </Link>
        <div className="flexMenuItem">
          <Link to={LinksEnum.Industrie}>
            <div>INDUSTRIE</div>
          </Link>
        </div>
        <ClicableMenuComponent />
      </div>
    </>
  );
};

export default Header;
