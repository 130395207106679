import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import mainPic from "../img/PagesVertes/Parquet/Parquet.jpg";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import { tileAccessoiresProps } from "../propsTiles/propsTiles";
import { RoundedGreenContainer } from "./commonComponents";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 2em;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ParquetDiv = styled.div`
  .lightTitle {
    color: #986c4a;
    font-size: 1.6em;
    text-align: center;
    margin-bottom: 2em;
  }

  h2 {
    font-size: 1.2em;
  }

  .accessoiresTile {
    display: flex;
    align-items: center;
    flex-direction:column;
    margin-top: 2em;
    justify-content: center;
    text-align: center;
  }

  .accessoiresTile p{
  width: 100%}
`;

const industrieContent: React.ReactNode = (
  <>
    <ParquetDiv>
      <p>
        Nous sommes une entreprise spécialisée dans la vente de parquets de
        haute qualité, dédiés à transformer vos intérieurs en espaces élégants
        et chaleureux. Forts de notre expertise et de notre engagement envers la
        satisfaction client, nous proposons une large gamme de parquets adaptés
        à tous les styles et budgets.
      </p>
      <h1 className="lightTitle">NOTRE GAMME DE PARQUET :</h1>
      <p>
        Nous offrons une sélection variée de parquets pour répondre à tous vos
        besoins et préférences esthétiques. Découvrez notre gamme complète :
      </p>
      <RowBois>
        <div className="boisContainer">
          <h2>PARQUET MASSIF</h2>
          <p>
            Les parquets massifs sont fabriqués à partir d'une seule pièce de
            bois, offrant une durabilité exceptionnelle et une beauté naturelle.
          </p>
          <p>
            <b>Chêne massif </b>: Réputé pour sa robustesse et ses magnifiques
            veines, disponible en plusieurs finitions.
          </p>
          <p>
            <b>Pin massif </b>: Apprécié pour sa teinte claire et son très bon
            rapport qualité prix, il est idéal dans les espaces de sommeil.
          </p>
        </div>
        <div className="boisContainer">
          <h2>PARQUET CONTRECOLLÉS</h2>
          <p>
            Les parquets contrecollés sont composés de plusieurs couches de
            bois, offrant une grande stabilité et une résistance aux variations
            climatiques.
          </p>
          <p>
            <b>Chêne contrecollé</b> : Alliant esthétisme et praticité,
            disponible en de nombreuses finitions et dimensions.
          </p>
          <p>Autres essences disponibles sur demande.</p>
        </div>
      </RowBois>
      <RoundedGreenContainer>
        <h1>AVANTAGES DE NOS PARQUETS</h1>
        <RowBois>
          <div className="boisContainer">
            <h2>Durabilité : </h2>
            <p>
              Nos parquets sont conçus pour durer, nous ne proposons que des
              parquets 100% bois (pas de contrecollé sur MDF, etc)
            </p>
          </div>
          <div className="boisContainer">
            <h2>Esthétisme : </h2>
            <p>
              Une large gamme de couleurs, de textures et de finitions pour
              s’adapter à tous les styles de décoration.
            </p>
          </div>
          <div className="boisContainer">
            <h2>Facilité d'entretien :</h2>
            <p>
              Nos parquets sont faciles à nettoyer et à entretenir, garantissant
              un aspect impeccable au fil des ans.
            </p>
          </div>
          <div className="boisContainer">
            <h2>Éco-responsabilité : </h2>
            <p>
              Bois issus de forêts gérées durablement, avec des certifications
              FSC et PEFC pour un achat respectueux de l’environnement.
            </p>
          </div>
        </RowBois>
      </RoundedGreenContainer>

      <h1 className="lightTitle">Applications courantes :</h1>
      <p>
        Nos parquets sont parfaits pour une variété d'applications, notamment :
      </p>
      <p>
        <b>Résidentiel</b> : Chambres, salons, cuisines et salles de bain.
      </p>
      <p>
        <b>Commercial </b>: Bureaux, magasins, restaurants et hôtels.
      </p>
      <p>
        <b>Rénovation</b> : Modernisation et amélioration esthétique des
        bâtiments existants.
      </p>

      <div className="accessoiresTile">
        <p>
          Découvrez tous nos accessoires sélectionnés spécialement par nos soins
          pour leur qualité
        </p>
        <TilePageDetail {...tileAccessoiresProps} />
      </div>
    </ParquetDiv>
  </>
);

export const propsForParquet: PageVerteCoreProps = {
  content: industrieContent,
  imageTitre: mainPic,
  titre: "PARQUET",
};
