import { PageResumeProps } from "../components/PageResume";
import tileImage1 from "../img/TuilesPagesRouges/AmenagementsInterieurs/échantillons_bois_aménagements_intérieurs.jpg";
import bottomImage from "../img/TuilesPagesRouges/AmenagementsInterieurs/aménagements_intérieurs_Maison_Charlet_bois.jpg";
import { tileAccessoiresProps, tileBoisPourAmenagementInterieursProps, tileDallesProps, tileFabricationMeublesEtAmenagementsProps, tileParquetProps, tileUsinagesSpecifiquesProps } from "../propsTiles/propsTiles";

export const amenagementsInterieursProps: PageResumeProps = {
  titre: "AMÉNAGEMENTS INTÉRIEURS",
  imageTitre: tileImage1,
  firstParagraph: `Nous sommes spécialistes du négoce et de la transformation de bois et de panneaux permettant l'ensemble de vos aménagements intérieurs. 

Nous proposons des bois de qualité menuiserie, des essences fines en Chêne français, Hêtre français, panneaux de medium et de contreplaqués divers, mais aussi la fabrication et la fourniture de claustras, de plinthes, de moulures, de parquets ou de lambris.

Nous pouvons aussi vous fournir l'ensemble des matériaux utilisés pour vos aménagements intérieurs ainsi que pour vos revêtements de murs et de sols :`,
  tile1: tileBoisPourAmenagementInterieursProps,
  tile2: tileDallesProps,
  tile3: tileParquetProps,
  tile4: tileFabricationMeublesEtAmenagementsProps,
  tile5: tileAccessoiresProps,
  tile6: tileUsinagesSpecifiquesProps,
  bottomImage: bottomImage,
  secondParagraph:
    "Notre équipe, via sa marque dédiée Maison Charlet, est également à votre écoute pour réaliser vos meubles sur mesure (tables, étagères, bancs, etc.) et vos aménagements intérieurs (placards, meubles sur mesure, banquettes etc.) incluant un service de pose.",
  thirdParagraph:
    "Nous fournissons tous les accessoires nécessaires pour vos réalisations tels que vis, plots, sabots, colle, etc.",
};
