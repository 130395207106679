import React, { useEffect } from "react";
import "./SawAnimation.css";
import Header from "./Header";
import Particle from "./ParticleFall";

const SawAnimation: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Faire défiler vers le haut lors du chargement initial de la page
  }, []);
  return (
    <>
      <Header />
      <div className="saw-container">
        <h1>Site en cours de rénovation</h1>
        <h2>Bientôt de nouvelles rubriques :)</h2>
        <div className="saw-animation">
          <div className="wood">
            {/* Cylindre vu de 3/4 */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 300 100"
              className="wood-log"
            >
              {/* Extrémités du rondin */}
              <ellipse cx="40" cy="50" rx="40" ry="10" fill="#8B4513" />
              <ellipse cx="260" cy="50" rx="40" ry="10" fill="#8B4513" />
              {/* Corps du rondin */}
              <rect
                x="0"
                y="30"
                width="300"
                height="40"
                fill="url(#woodGradient)"
              />
              {/* Dégradé pour le corps du rondin */}
              <defs>
                <linearGradient
                  id="woodGradient"
                  x1="0%"
                  y1="0%"
                  x2="0%"
                  y2="100%"
                >
                  <stop
                    offset="0%"
                    style={{ stopColor: "#8B4513", stopOpacity: 1 }}
                  />
                  <stop
                    offset="100%"
                    style={{ stopColor: "#D2691E", stopOpacity: 1 }}
                  />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="saw">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 50"
              className="saw-blade"
            >
              {/* Inversion des dents */}
              <path
                d="M 0 25 L 3.33 45 L 6.67 25 L 10 45 L 13.33 25 L 16.67 45 L 20 25 L 23.33 45 L 26.67 25 L 30 45 L 33.33 25 L 36.67 45 L 40 25 L 43.33 45 L 46.67 25 L 50 45 L 53.33 25 L 56.67 45 L 60 25 L 63.33 45 L 66.67 25 L 70 45 L 73.33 25 L 76.67 45 L 80 25 L 83.33 45 L 86.67 25 L 90 45 L 95 25 L 98 45 L 103.33 25 L 106.67 45 L 110 25 L 113.33 45 L 116.67 25 L 120 45 L 123.33 25 L 126.67 45 L 130 25 L 133.33 45 L 136.67 25 L 140 45 L 143.33 25 L 146.67 45 L 150 25 L 153.33 45 L 156.67 25 L 160 45 L 163.33 25 L 166.67 45 L 170 25 L 173.33 45 L 176.67 25 L 180 45 L 183.33 25 L 186.67 45 L 190 25 L 193.33 45 L 196.67 25 L 200 45 L 203.33 25 L 206.67 45 L 210 25 L 213.33 45 L 216.67 25 L 220 45 L 223.33 25 L 226.67 45 L 230 25 L 233.33 45 L 236.67 25 L 240 45 L 243.33 25 L 246.67 45 L 250 25 L 253.33 45 L 256.67 25 L 260 45 L 263.33 25 L 266.67 45 L 270 25 L 273.33 45 L 276.67 25 L 280 45 L 283.33 25 L 286.67 45 L 290 25 L 293.33 45 L 296.67 25 L 300 45 L 301 -29 C 176 -29 132 -20 68 -9 C 3 5 5 7 0 25"
                style={{ fill: "silver", stroke: "black", strokeWidth: 2 }}
              />
            </svg>
            <div className="saw-handle"></div>
          </div>

          <Particle />
        </div>
      </div>
    </>
  );
};

export default SawAnimation;
