import { styled } from "styled-components";

export const TitleComponent = styled.div<{ imageTitre: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.imageTitre});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: 2em;
`;