enum LinksEnum {
    Industrie = "/bois-pour-industrie-coffrage-charpente-menuiserie",
    Coffrage = "/coffrage-panneaux-filmes-usinages-specifiques",
    Charpente = "/charpente-couverture-ossature-bois",
    AmenagementsInterieurs = "/amenagements-interieurs",
    AmenagementsExterieurs = "/amenagements-exterieurs",
    BoisDeCoffrage = "/bois-de-coffrage-charpentes-temporaires-pliquets-planches-de-securité",
    BoisDeCharpente = "/bois-de-charpente-ossature-bois",
    BoisPourIndustrie = "/bois-massif-chene-hetre-pin-bois-lamelle-colle-contreplaque",
    PanneauxFilmes = "/panneaux-filmes-negoce-bois",
    DallesEtPanneauxNonFilmes = "/dalles-osb-et-panneaux-non-filmes-contreplaque-panneaux-mdf",
    UsinagesSpecifiques = "/usinages-specifiques",
    BoisPourAmenagementsInterieurs = "/bois-amenagements-interieurs-plinthes-moulures-claustras-menuiserie",
    BoisPourAmenagementsExterieurs = "/bois-terrasse-bardages-clotures-portillons-portails-pergolas",
    FabricationCoffrageEtMannequins = "/fabrication-coffrage-mannequins-bois-aluminium",
    FabricationsSpecifiques = "/fabrications-bois-specifiques",
    Parquet = "/parquet-massif-contrecolle",
    TerrasseBardage = "/terrasse-bardage-bois-exotiques-bois-resineux",
    OssatureBois = "/fabrications-ossatures-bois",
    CaisseEmballage = "/caisses-emballage",
    Accessoires = "/accessoires-construction-colle-huile-entretien-de-bardage",
    FAQ = "/questions-courantes",
    Contact = "/contact"
  }
  
  export default LinksEnum;