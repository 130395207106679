import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import mainPic from "../img/PagesVertes/Accessoires/Accessoires.jpg";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import { tileBoisPourAmenagementExterieursProps } from "../propsTiles/propsTiles";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;
  @media (min-width: 768px) {
    gap: 4em;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const industrieContent: React.ReactNode = (
  <>
    <p>
      Nous fournissons une large gamme d'accessoires pour l'ensemble de vos
      besoins en constructions et en aménagements.
    </p>
    <h1>ACCESSOIRES POUR CONSTRUCTIONS ET AMÉNAGEMENTS EXTÉRIEURS</h1>
    <RowBois>
      <div className="bois-container">
        <p>
          Bande d'isolation pour lambourdes <br /> Systèmes de fixations
          invisibles <br />
          Plots de terrasse <br /> Connecteurs laqués ou galvanisés <br />
          Equerres galvanisées
        </p>
      </div>
      <div className="bois-container">
        <p>
          Vis de terrasse inox <br />
          Vis de penture <br />
          Vis de fondation 550mm à 1750mm <br />
          Kit perçage / Fraisage <br />
          Chevilles à frapper <br />
          Tirefonds
        </p>
      </div>
    </RowBois>
    <h1>ACCESSOIRES POUR CONSTRUCTIONS ET AMÉNAGEMENTS INTÉRIEURS</h1>
    <RowBois>
      <div className="bois-container">
        <p>
          Vis tous diamètres et toutes longueurs <br /> Longueurs
          <br />
          Pointes crantées <br /> Equerres de cheneaux <br />
          Tirants de sablières
        </p>
      </div>
      <div className="bois-container">
        <p>Goujons <br />Sabots de solives <br />Kit perçage / Fraisage <br />Forêts et embouts de vissage <br />
        </p>
      </div>
    </RowBois>
    <h1>ACCESSOIRES POUR POSE ET ENTRETIEN DE BARDAGE</h1>
    <RowBois>
      <div className="bois-container">
        <p>
          Produit dégrisant
          <br />
          Produit de traitement insecticide / fongicide
          <br />
          Produit de traitement autoclave <br /> Pare-pluie <br />
        </p>
      </div>
      <div className="bois-container">
        <p>
          Grille anti-rongeur <br />
          Pointe inox pour bardage <br />
          Vis inox pour bardage <br />
        </p>
      </div>
    </RowBois>
    <h1>ACCESSOIRES COLLE ET HUILE</h1>
    <RowBois>
      <div className="bois-container">
        <p>
          Nous commercialisons l'intégralité de la gamme Rubio pour l'entretien,
          la rénovation et la protection de vos bois.
        </p>
        <p>Colle à parquet</p>
        <p>Colle à bois</p>
      </div>
    </RowBois>
    <p>
      Vous êtes à la recherche de bois pour réaliser vos aménagements extérieurs
      ? (clôtures, terrains de pétanque, etc) Consultez notre rubrique dédiée :
    </p>
    <TilePageDetail {...tileBoisPourAmenagementExterieursProps} />
  </>
);

export const propsForAccessoires: PageVerteCoreProps = {
  content: industrieContent,
  imageTitre: mainPic,
  titre: "ACCESSOIRES",
};
