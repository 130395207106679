import React from "react";
import { Link } from "react-router-dom"; // Si vous utilisez react-router pour la navigation
import styled from "styled-components";

// Styled Components
const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  align-items: center;
  padding: 1rem;
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  margin-top: 8em;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 0.5em;
    font-size: 0.8em;
  }

  @media (min-width: 768px) {
    gap: 3em;
    font-size: 1em;
  }
`;

const Contact = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const PhoneIcon = styled.span`
  margin-right: 0.5rem;
`;

const Legal = styled.div`
  color: #000;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <div>Charlet Bois - 70 rue Félix Faure, 59350 Saint-André-Lez-Lille</div>
      <Contact>
        <p>
          ✉︎ Email:{" "}
          <a href="mailto:accueil@charlet-bois.com">accueil@charlet-bois.com</a>
        </p>
        <PhoneIcon>☎︎ </PhoneIcon> 03 28 38 83 83
      </Contact>
      <Legal>
        <StyledLink to="/mentions-legales">Mentions légales</StyledLink>
      </Legal>
    </FooterWrapper>
  );
};

export default Footer;
