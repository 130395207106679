import styled from "styled-components";
import saw from "../img/saw.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align:center;
  align-items:center
`;

const ImageContainer = styled.img`
  width: 5em;
`;

export const BoisScieEnFrance = () => {
  return (
    <>
      <Container>
        <h2>Bois sciés en France</h2>
        <ImageContainer
          src={saw}
          alt="Scie représentant le fait ques les bois Charlet Bois soient sciés en France"
        ></ImageContainer>
      </Container>
    </>
  );
};
