import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import mainImage from "../img/PagesVertes/Panneaux/Panneaux_non_filmes.jpg";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import {
  tileAccessoiresProps,
  tilePanneauxFilmes,
} from "../propsTiles/propsTiles";
import { RoundedGreenContainer } from "./commonComponents";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .boisContainer {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    .boisContainer {
      width: 20%;
    }
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .boisContainer p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
`;

const DallesContainingDiv = styled.div`
  .lightTitle {
    color: #c8a581;
    font-size: 1.6em;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  h2 {
    font-size: 1.2em;
    margin-top: 1.4em;
    margin-bottom: 1.4em;
  }
`;

const industrieContent: React.ReactNode = (
  <>
    <DallesContainingDiv>
      <p>
        Nous proposons une large sélection de panneaux de bois et de dalles OSB
        pour répondre à toutes vos exigences techniques et esthétiques.
      </p>
      <p>
        Nous offrons une variété de bois sélectionnés pour répondre aux
        exigences spécifiques des applications industrielles. Voici un aperçu
        des principaux types de bois disponibles :
      </p>
      <h1 className="lightTitle">1. PANNEAUX DE CONTREPLAQUÉ NON FILMÉS</h1>
      <p>
        Les panneaux de contreplaqué sont composés de plusieurs couches de bois
        collées ensemble, offrant robustesse et polyvalence.
      </p>
      <p>
        Les épaisseurs les plus courantes sont 5mm, 7mm, 9mm, 12mm, 15mm, 18mm,
        21mm avec des épaisseurs pouvant aller jusqu'à 30mm.
      </p>
      <p>
        Les formats les plus courants sont de 1220 x 2500 et de 1250 x 3000.
      </p>
      <RowBois>
        <div className="boisContainer">
          <h2>Contreplaqué standard:</h2>
          <p>
            Idéal pour les usages courants en construction et aménagement
            intérieur.
          </p>
        </div>
        <div className="boisContainer">
          <h2>Contreplaqué marin:</h2>
          <p>
            Idéal pour les usages courants en construction et aménagement
            intérieur.
          </p>
        </div>
        <div className="boisContainer">
          <h2>Autres panneaux de contreplaqué sur demande</h2>
        </div>
      </RowBois>
      <RowBois>
        <div className="boisContainer">
          <p>Besoin d'informations sur les panneaux filmés ?</p>
          <TilePageDetail {...tilePanneauxFilmes}></TilePageDetail>
        </div>
      </RowBois>
      <h1 className="lightTitle">
        2. PANNEAUX MDF (MEDIUM DENSITY FIBERBOARD)
      </h1>
      <p>
        Le MDF est un panneau de fibres de bois compressées, connu pour sa
        densité uniforme et sa facilité de finition.
      </p>
      <p>
        Les épaisseurs les plus courantes sont 3mm, 6mm, 9mm, 12mm, 15mm, 18mm,
        22mm avec des épaisseurs pouvant aller jusqu'à 30mm.
      </p>
      <p>
        Les formats les plus courants sont de 1220 x 2440 et de 1250 x 3000.
      </p>
      <RowBois>
        <div className="boisContainer">
          <h2>MDF standard : </h2>
          <p>Utilisé pour les meubles, les portes, et les plinthes.</p>
        </div>
        <div className="boisContainer">
          <h2>MDF hydrofuge :</h2>
          <p>
            Résistant à l'humidité, adapté pour les cuisines et les salles de
            bains.
          </p>
        </div>
        <div className="boisContainer">
          <h2>Autres panneaux de MDF sur demande</h2>
        </div>
      </RowBois>
      <h1 className="lightTitle">3. PANNEAUX AGGLOMÉRÉS</h1>
      <p>
        Fabriqués à partir de particules de bois compressées et collées, les
        panneaux agglomérés sont économiques et polyvalents.
      </p>
      <RowBois>
        <div className="boisContainer">
          <h2>Aggloméré standard :</h2>
          <p>
            Utilisé dans la fabrication de meubles et les aménagements
            intérieurs.
          </p>
        </div>
        <div className="boisContainer">
          <h2>Aggloméré mélaminé :</h2>
          <p>
            Revêtu d’une couche de mélamine, disponible en différentes couleurs
            et finitions.
          </p>
        </div>
      </RowBois>
      <h1 className="lightTitle">
        4. DALLES ET PANNEAUX OSB (ORIENTED STRAND BOARD)
      </h1>
      <p>
        L'OSB est constitué de lamelles de bois orientées et collées, offrant
        une excellente résistance mécanique.
      </p>
      <p>
        Nous vendons essentiellement de l'OSB 3, pour des usages intérieurs et
        extérieurs en milieu humide.
      </p>
      <p>
        Les panneaux d' OSB 3 sont disponibles en format 1250 x 2500 et sont à
        bords droits.
      </p>
      <p>
        Les dalles d' OSB 3 sont disponbiles en format 590 x 2440 et sont à
        bords rainures et languettes, permettant l'emboîtement.
      </p>
      <RoundedGreenContainer>
        <h1>AVANTAGE DE NOS PANNEAUX ET DALLES OSB</h1>
        <h2>Robustesse</h2>
        <p>
          Nos produits sont conçus pour offrir une grande résistance et
          durabilité.
        </p>
        <h2>Polyvalence </h2>
        <p>
          Une large gamme de panneaux pour tous types de projets, de la
          construction à l'aménagement.
        </p>
        <h2>Respect de l'environnement</h2>
        <p>Bois issu de forêts gérées durablement, certifié FSC et PEFC.</p>
      </RoundedGreenContainer>
      <RowBois>
        <div className="boisContainer">
          <p>
            Découvrez tous nos accessoires sélectionnés spécialement par nos
            soins pour leur qualité
          </p>
          <TilePageDetail {...tileAccessoiresProps} />
        </div>
      </RowBois>
    </DallesContainingDiv>
  </>
);

export const propsForDallesNonFilmees: PageVerteCoreProps = {
  content: industrieContent,
  imageTitre: mainImage,
  titre: "DALLES & PANNEAUX NON FILMÉS",
};
