import { styled } from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

const ContainingDiv = styled.div`
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top:2em;
`;

const MentionsLegales = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <ContainingDiv>
        MENTIONS LÉGALES En vigueur au 10/04/2024 Conformément aux dispositions
        des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la
        Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la
        connaissance des utilisateurs et visiteurs, ci-après l""Utilisateur", du
        site https://www.charlet-bois.com , ci-après le "Site", les présentes
        mentions légales. La connexion et la navigation sur le Site par
        l’Utilisateur implique acceptation intégrale et sans réserve des
        présentes mentions légales. Ces dernières sont accessibles sur le Site à
        la rubrique « Mentions légales ». ARTICLE 1 - L'EDITEUR L'édition du
        Site est assurée par ETABLISSEMENTS ALBERT CHARLET SAS au capital de
        300000 euros, immatriculée au Registre du Commerce et des Sociétés de
        Lille Métropole sous le numéro 45650110500013 dont le siège social est
        situé au 70 rue Félix Faure 59350 SAINT ANDRE LEZ LILLE, Numéro de
        téléphone 0328388383, Adresse e-mail : maison@charlet-bois.com. N° de
        TVA intracommunautaire : TVA FR00 456 501 105 Le Directeur de la
        publication est Jean-Baptiste MIELLET ci-après l'"Editeur". ARTICLE 2 -
        L'HEBERGEUR L'hébergeur du Site est la société Netlify, Inc, dont le
        siège social est situé au 44 Montgomery Street, Suite 300, San
        Francisco, California 94104 ARTICLE 3 - ACCES AU SITE Le Site est
        accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure,
        interruption programmée ou non et pouvant découlant d’une nécessité de
        maintenance. En cas de modification, interruption ou suspension du Site,
        l'Editeur ne saurait être tenu responsable. ARTICLE 4 - COLLECTE DES
        DONNEES Le Site assure à l'Utilisateur une collecte et un traitement
        d'informations personnelles dans le respect de la vie privée
        conformément à la loi n°78-17 du 6 janvier 1978 relative à
        l'informatique, aux fichiers et aux libertés. En vertu de la loi
        Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur
        dispose d'un droit d'accès, de rectification, de suppression et
        d'opposition de ses données personnelles. L'Utilisateur exerce ce droit
        : · via un formulaire de contact ; Toute utilisation, reproduction,
        diffusion, commercialisation, modification de toute ou partie du Site,
        sans autorisation de l’Editeur est prohibée et pourra entraînée des
        actions et poursuites judiciaires telles que notamment prévues par le
        Code de la propriété intellectuelle et le Code civil.
      </ContainingDiv>
      <Footer />
    </>
  );
};

export default MentionsLegales;
