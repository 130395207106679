import { styled } from "styled-components";

export const RoundedGreenContainer = styled.div`
  background-color: #f2f6f0;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em;
  margin-bottom: 2em;
  margin-top: 2em;

  h1 {
    color: #0e2d20;
    font-size:1.5em;
  }
`;
