import { TileProps } from "../components/TilePageDetail";
import amenagementImg from "../img/TuilesPagesRouges/AmenagementsInterieurs/Bois_pour_aménagements_intérieurs.png";
import LinksEnum from "../routes/routesReference";
import dalleImg from "../img/TuilesPagesRouges/AmenagementsInterieurs/Dalles et panneaux non filmés.png";
import parquetImg from "../img/TuilesPagesRouges/AmenagementsInterieurs/parquet.png";
import fabricationImg from "../img/TuilesPagesRouges/AmenagementsInterieurs/fabrication_meubles_aménagements.png";
import terrassesEtBardage from "../img/TuilesPagesRouges/Terrasses/terrasse_bardages.png";
import accessoiresImg from "../img/TuilesPagesRouges/AmenagementsInterieurs/accessoires.png";
import usinagesImg from "../img/TuilesPagesRouges/AmenagementsInterieurs/usinages spécifiques.png";
import charpenteImg from "../img/TuilesPagesRouges/Charpente/Bois de charpente, de couverture et d'ossature.png";
import fabricationsSpecifiquesImg from "../img/TuilesPagesRouges/Charpente/fabrications_spécifiques.png";
import ossatureBoisImg from "../img/TuilesPagesRouges/Charpente/ossatures_bois.png";
import caisseEmballageImg from "../img/TuilesPagesRouges/Industrie/caisses_emballage.png";
import boisPourIndustrieImg from "../img/TuilesPagesRouges/Industrie/Bois de charpente, de couverture et d'ossature.png";
import boisCoffrageImg from "../img/TuilesPagesRouges/Coffrage/Bois_de_coffrage.png";
import panneauxFilmesImg from "../img/TuilesPagesRouges/Coffrage/Panneaux filmés.png";
import fabricationCoffrageEtMannequinsImg from "../img/TuilesPagesRouges/Coffrage/Fabrication_coffrage_mannequins.png";

export const tileFabricationsCoffrageEtMannequins: TileProps = {
  altImage: "",
  mainImage: fabricationCoffrageEtMannequinsImg,
  titre: "FABRICATION COFFRAGES ET MANNEQUINS",
  linkTo: LinksEnum.FabricationCoffrageEtMannequins,
};

export const tileBoisDeCoffrage: TileProps = {
  altImage: "",
  mainImage: boisCoffrageImg,
  titre: "BOIS DE COFFRAGE",
  linkTo: LinksEnum.BoisDeCoffrage,
};

export const tilePanneauxFilmes: TileProps = {
  altImage: "",
  mainImage: panneauxFilmesImg,
  titre: "PANNEAUX FILMÉS",
  linkTo: LinksEnum.PanneauxFilmes,
};

export const tileCaisseEmballageProps: TileProps = {
  altImage: "",
  mainImage: caisseEmballageImg,
  titre: "CAISSES D'EMBALLAGE",
  linkTo: LinksEnum.CaisseEmballage,
};

export const tileBoisPourIndustrieProps: TileProps = {
  altImage: "",
  mainImage: boisPourIndustrieImg,
  titre: "BOIS POUR INDUSTRIE, COFFRAGES, CHARPENTE, MENUISERIE",
  linkTo: LinksEnum.BoisPourIndustrie,
};

export const tileBoisPourAmenagementInterieursProps: TileProps = {
  altImage: "",
  mainImage: amenagementImg,
  titre: "BOIS POUR AMÉNAGEMENTS INTÉRIEURS",
  linkTo: LinksEnum.BoisPourAmenagementsInterieurs,
};

export const tileDallesProps: TileProps = {
  altImage: "",
  mainImage: dalleImg,
  titre: "DALLES ET PANNEAUX NON FILMÉS",
  linkTo: LinksEnum.DallesEtPanneauxNonFilmes,
};

export const tileParquetProps: TileProps = {
  altImage: "",
  mainImage: parquetImg,
  titre: "PARQUET",
  linkTo: LinksEnum.Parquet,
};

export const tileFabricationMeublesEtAmenagementsProps: TileProps = {
  altImage: "",
  mainImage: fabricationImg,
  titre: "FABRICATIONS MEUBLES ET AMÉNAGEMENTS",
  linkTo: "http://maisoncharlet.fr",
};

export const tileForTerrasseEtBardage: TileProps ={
  altImage: "",
  mainImage: terrassesEtBardage,
  titre: "TERRASSE ET BARDAGE",
  linkTo: LinksEnum.TerrasseBardage,
};

export const tileBoisPourAmenagementExterieursProps: TileProps = {
  altImage: "",
  mainImage: amenagementImg,
  titre: "BOIS POUR AMENAGEMENTS EXTERIEURS",
  linkTo: LinksEnum.BoisPourAmenagementsExterieurs,
};

export const tileAccessoiresProps: TileProps = {
  altImage: "",
  mainImage: accessoiresImg,
  titre: "ACCESSOIRES",
  linkTo: LinksEnum.Accessoires,
};

export const tileUsinagesSpecifiquesProps: TileProps = {
  altImage: "",
  mainImage: usinagesImg,
  titre: "USINAGES SPÉCIFIQUES",
  linkTo: LinksEnum.UsinagesSpecifiques,
};

export const tileBoisDeCharpenteCouvertureOssature: TileProps = {
  altImage: "",
  mainImage: charpenteImg,
  titre: "BOIS DE CHARPENTE, DE COUVERTURE & D'OSSATURE",
  linkTo: LinksEnum.BoisDeCharpente,
};

export const tileFabricationsSpecifiquesProps: TileProps = {
  altImage: "",
  mainImage: fabricationsSpecifiquesImg,
  titre: "FABRICATIONS SPECIFIQUES",
  linkTo: LinksEnum.FabricationsSpecifiques,
};

export const tileOssatureBois: TileProps = {
  altImage: "",
  mainImage: ossatureBoisImg,
  titre: "OSSATURES BOIS",
  linkTo: LinksEnum.OssatureBois,
};
