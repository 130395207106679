import styled from "styled-components";
import certif from "../img/certifBoisPEFC.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align:center;
  align-items:center
`;

const ImageContainer = styled.img`
  width: 5em;
`;

export const ForetCertifiees = () => {
  return (
    <>
      <Container>
        <h2>Forêts françaises certifiées PEFC</h2>
        <ImageContainer
          src={certif}
          alt="Le bois Charlet Bois est issu de forêt françaises certifiées PEFC"
        >
        </ImageContainer>
      </Container>
    </>
  );
};
