import Header from "./Header";
import { TitleComponent } from "./TitleComponent";
import "./PageVerteCore.css";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

export interface PageVerteCoreProps {
  content: React.ReactNode;
  imageTitre: string;
  titre: string;
}

const PageVerteCore: React.FC<PageVerteCoreProps> = ({
  content,
  imageTitre,
  titre,
}) => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <TitleComponent imageTitre={imageTitre}>
        <div className="titleElement">{titre}</div>
      </TitleComponent>
      <div className="pageVerteContent">
        <>{content}</>
      </div>
      <Footer />
    </>
  );
};

export default PageVerteCore;
