import { PageResumeProps } from "../components/PageResume";
import tileImage1 from "../img/TuilesPagesRouges/AmenagementsExterieurs/couverture_bois_massif_aménagements_extérieurs.jpg.jpg";

import bottomImage from "../img/TuilesPagesRouges/AmenagementsExterieurs/terrasse_aménagement_extérieur_lafriche.jpg";

import {
  tileAccessoiresProps,
  tileFabricationMeublesEtAmenagementsProps,
  tileBoisPourAmenagementExterieursProps,
  tileForTerrasseEtBardage,
  tileUsinagesSpecifiquesProps,
} from "../propsTiles/propsTiles";

export const amenagementsExterieursProps: PageResumeProps = {
  titre: "AMÉNAGEMENTS EXTÉRIEURS ET TERRASSES",
  imageTitre: tileImage1,
  firstParagraph: `Charlet Bois est spécialiste du négoce et de la transformation de bois permettant l'ensemble de vos aménagements extérieurs.
Nous pouvons vous fournir l'ensemble des matériaux pour vos aménagements extérieurs, pouvant être transformés dans nos ateliers près de Lille (mise à longueur, rabotage, délignage, moulurage etc.)

Nous proposons des bois de terrasse et de bardage dans de nombreuses essences différentes, locales et exotiques, traverses paysagères en chêne pour marches extérieures, retenues de terre ou terrains de pétanque, panneaux de contreplaqués divers, etc.
Consultez nous pour vos différents besoins. `,
  tile1: tileBoisPourAmenagementExterieursProps,
  tile2: tileForTerrasseEtBardage,
  tile3: tileUsinagesSpecifiquesProps,
  tile4: tileFabricationMeublesEtAmenagementsProps,
  tile5: tileAccessoiresProps,
  bottomImage: bottomImage,
  secondParagraph: (
    <span>
      Notre équipe, via sa marque dédiée{" "}
      <a href="http://www.maisoncharlet.fr">Maison Charlet</a>, est également à
      votre écoute pour réaliser vos meubles sur mesure (tables, bancs, etc.) et
      vos aménagements extérieurs (meubles sur mesure, banquettes etc.) incluant
      un service de pose.
    </span>
  ),
  thirdParagraph: `Nous fournissons tous les accessoires nécessaires pour vos réalisations tels que vis, plots, sabots, colle, etc.`,
};
