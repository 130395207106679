import styled from "styled-components";
import "./Tile.css";

interface BoisdDivProps {
  backgroundImage: string;
  text: string;
  altImage: string;
}

const Container = styled.div<{ backgroundImage: string;}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  aspect-ratio: 1 / 1;
  background-position: center;
  max-width:200px;
  max-height:200px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  text-align: center;
  font-weight:500;
  font-size:1.2em;

  &:hover {
    transform: scale(1.05);
  }
`;
const TileBois: React.FC<BoisdDivProps> = ({
  text,
  backgroundImage,
  altImage
}) => {
  return (
    <Container
      aria-label={altImage}
      backgroundImage={backgroundImage}
      className="tileContainer"
    >
      {text}
    </Container>
  );
};

export default TileBois;
