import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import BoisDeCharpente from "../img/PagesVertes/Industrie/Bois_pour_industrie.jpg";
import ScieAndCertif from "../components/ScieAndCertif";
import cheneImg from "../img/PagesVertes/Industrie/Chêne.jpg";
import hetreImg from "../img/PagesVertes/Industrie/Hêtre.jpg";
import pinImg from "../img/PagesVertes/Industrie/Pin.jpg";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import {
  tileAccessoiresProps,
  tileDallesProps,
  tileUsinagesSpecifiquesProps,
} from "../propsTiles/propsTiles";
import TileBois from "../components/TileBois";

const DivRowImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 5em;
  align-items: center;
  justify-content: center;

  .rowDalle {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const DivImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    width: 33%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RowBois = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const industrieContent: React.ReactNode = (
  <>
    <p>
      Tous nos bois peuvent être traités par nos soins pour les rendre de classe
      II, et ainsi être propre à l'usage dans les structures des différentes
      constructions.
    </p>
    <ScieAndCertif></ScieAndCertif>
    <p>
      Nous offrons une variété de bois sélectionnés pour répondre aux exigences
      spécifiques des applications industrielles. Voici un aperçu des principaux
      types de bois disponibles :
    </p>
    <h2>1. BOIS MASSIF</h2>
    <p>
      Idéal pour les constructions lourdes et les structures robustes, le bois
      massif offre une résistance exceptionnelle et une durabilité sans égale.
    </p>
    <RowBois>
      <div className="boisContainer">
        <TileBois
          altImage=""
          backgroundImage={cheneImg}
          text="CHÊNE"
        ></TileBois>
        <p>
          Connu pour sa solidité et sa longévité, parfait pour les structures
          porteuses.
        </p>
      </div>
      <div className="boisContainer">
        <TileBois
          altImage=""
          backgroundImage={hetreImg}
          text="HÊTRE"
        ></TileBois>
        <p>
          Bois dur polyvalent, excellent pour les meubles industriels et les
          éléments architecturaux.
        </p>
      </div>
      <div className="boisContainer">
        <TileBois altImage="" backgroundImage={pinImg} text="PIN"></TileBois>
        <p>
          Léger mais robuste, couramment utilisé pour les palettes et les
          emballages industriels.
        </p>
      </div>
    </RowBois>
    <h2>2. BOIS LAMÉLLÉ-COLLÉ</h2>
    <p>
      Ce matériau composite est fabriqué en collant ensemble plusieurs couches
      de bois, offrant une grande stabilité dimensionnelle et une résistance
      accrue.
    </p>
    <DivRowImages>
      <DivImageContainer>
        <h2>Épicéa lamellé-collé</h2>
        <p>
          Utilisé pour les structures de grandes envergures notamment dans les
          bâtiments industriels.
        </p>
      </DivImageContainer>
      <DivImageContainer>
        <h2>Douglas lamellé-collé</h2>
        <p>
          Résistant aux intempéries et aux insectes, idéal pour les applications
          extérieures.
        </p>
      </DivImageContainer>
    </DivRowImages>
    <h3>3. CONTREPLAQUÉ</h3>
    <p>
      Fabriqué en collant des couches minces de bois en croisant les fibres, le
      contreplaqué est robuste, léger et polyvalent.
    </p>
    <DivRowImages>
      <div className="rowDalle">
        <TilePageDetail {...tileDallesProps}></TilePageDetail>
      </div>
      <DivImageContainer>
        <p>
          Chacun de nos panneaux et de nos bois peuvent être retravaillés de
          façon à répondre à vos besoins les plus spécifiques.
        </p>
        <TilePageDetail {...tileUsinagesSpecifiquesProps}></TilePageDetail>
      </DivImageContainer>
      <DivImageContainer>
        <p>
          Découvrez tous nos accessoires sélectionnés spécialement par nos soins
          pour leur qualité
        </p>
        <TilePageDetail {...tileAccessoiresProps}></TilePageDetail>
      </DivImageContainer>
    </DivRowImages>
  </>
);

export const propsForIndustrie: PageVerteCoreProps = {
  content: industrieContent,
  imageTitre: BoisDeCharpente,
  titre: "BOIS POUR L'INDUSTRIE",
};
