import "./WelcomePage.css";
import WelcomePageCarousel from "./WelcomePageCarousel";
import image1Carousel from "../img/Coffrage_charlet_bois.jpg";
import image2Carousel from "../img/decoupe_fabrication_charlet_bois.jpg";
import tileImage1 from "../img/TuilesAcceuil/Coffrage.jpg";
import tileImage2 from "../img/TuilesAcceuil/Charpente_couverture_ossature.jpg";
import tileImage3 from "../img/TuilesAcceuil/Industrie.jpg";
import tileImage4 from "../img/TuilesAcceuil/Aménagement_intérieurs.jpg";
import tileImage5 from "../img/TuilesAcceuil/Aménagements_extérieurs.jpg";
import Tile from "./Tile";
import LinksEnum from "../routes/routesReference";
import Footer from "./Footer";

const imagesCarousel = [image1Carousel, image2Carousel];

function WelcomePage() {
  return (
    <>
      <WelcomePageCarousel images={imagesCarousel}></WelcomePageCarousel>
      <div className="tilesMetiers">
        <Tile
          text="COFFRAGE"
          backgroundImage={tileImage1}
          altImage="Charlet Bois est spécialisé dans la négoce de bois et de panneaux de coffrage, en particulier pour l'industrie du BTP avec un stock de plus de 15 000 m², situé près de Lille."
          width="30%"
          link={LinksEnum.Coffrage}
        ></Tile>
        <Tile
          text="CHARPENTE, COUVERTURE, OSSATURE BOIS"
          backgroundImage={tileImage2}
          altImage="Charlet Bois est spécialisé dans négoce de bois et de panneaux servant à la construction de charpentes ou d'extensions en ossature bois avec un stock de plus de 15 000 m², situé près de Lille."
          width="30%"
          link={LinksEnum.Charpente}
        ></Tile>
        <Tile
          text="INDUSTRIE"
          backgroundImage={tileImage3}
          altImage="Nous proposons des bois de coffrage, bois de qualité charpente ou menuiserie, prototypage en bois dur (Chêne, Hêtre, Bois exotique), panneaux en bois petits et grands formats etc."
          width="30%"
          link={LinksEnum.Industrie}
        ></Tile>
        <Tile
          text="AMENAGEMENTS INTERIEURS"
          backgroundImage={tileImage4}
          altImage="Charlet Bois propose des bois de qualité menuiserie, des essences fines en Chêne français, Hêtre français, panneaux de medium et de contreplaqués divers, mais aussi la fabrication et la fourniture de claustras, de plinthes, de moulures, de parquets ou de lambris."
          width="30%"
          link={LinksEnum.AmenagementsInterieurs}
        ></Tile>
        <Tile
          text="AMENAGEMENTS EXTERIEURS"
          backgroundImage={tileImage5}
          altImage="Charlet Bois est spécialiste du négoce et de la transformation de bois permettant l'ensemble de vos aménagements extérieurs."
          width="30%"
          link="/coffrage-panneaux-filmes-usinages-specifiques"
        ></Tile>
      </div>
      <Footer />
    </>
  );
}

export default WelcomePage;
