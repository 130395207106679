import { PageResumeProps } from "../components/PageResume";
import tileImage1 from "../img/TuilesPagesRouges/Coffrage/Coffrage.jpg";
import bottomImage from "../img/TuilesPagesRouges/Coffrage/coffrage_charlet_bois.jpg";
import {
  tileAccessoiresProps,
  tileBoisDeCoffrage,
  tileFabricationsCoffrageEtMannequins,
  tilePanneauxFilmes,
  tileUsinagesSpecifiquesProps,
} from "../propsTiles/propsTiles";

export const coffrageProps: PageResumeProps = {
  titre: "COFFRAGE",
  imageTitre: tileImage1,
  firstParagraph:
    "Nous sommes spécialistes du négoce de bois et de panneaux de coffrage, en particulier pour l'industrie du BTP avec un stock de plus de 15 000 m², situé près de Lille. Nos ateliers sont capables de travailler l'ensemble de nos produits afin de répondre à vos besoins les plus spécifiques : bois coupés à longueur, délignés, rabotés, moulurés, débit de panneaux, gabarits de cintrage, etc.",
  tile1: tileBoisDeCoffrage,
  tile2: tilePanneauxFilmes,
  tile3: tileUsinagesSpecifiquesProps,
  tile4: tileFabricationsCoffrageEtMannequins,
  tile5: tileAccessoiresProps,
  bottomImage: bottomImage,
  secondParagraph:
    "Notre société est leader dans la fabrication de mannequins et de coffrages en bois et en aluminium. Notre atelier de 3500m², situé à côté de Lille, peut réaliser rapidement et avec soin vos besoins.",
  thirdParagraph:
    "Nous fournissons également l'ensemble des accessoires nécessaires à toutes vos réalisations (visserie, plots, sabots, colle, etc.)",
};
