import React from "react";
import WelcomePage from "./components/WelcomePage";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <WelcomePage />
    </div>
  );
}

export default App;
