import { Link } from "react-router-dom";
import styled from "styled-components";
import "./Tile.css";

interface BackgroundDivProps {
  backgroundImage: string;
  text: string;
  width: string;
  altImage: string;
  link:string;
}

const Container = styled.div<{ backgroundImage: string; width: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  width: ${(props) => props.width};
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  text-align:center;
  &:hover {
    transform: scale(1.05);
  }
`;
const Tile: React.FC<BackgroundDivProps> = ({
  text,
  backgroundImage,
  altImage,
  width,
  link
}) => {
  return (
    <Container
      aria-label={altImage}
      backgroundImage={backgroundImage}
      width={width}
      className="tileContainer"
    >
      <Link className="linkWelcomePage" to={link}>
        {text}
      </Link>
    </Container>
  );
};

export default Tile;
