import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "./TilePageDetail.css";

export interface TileProps {
  mainImage: string;
  titre: string;
  altImage: string;
  linkTo: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;

const TilePageDetail: React.FC<TileProps> = ({
  mainImage,
  titre,
  altImage,
  linkTo
}) => {
  return (
    <Container className="tilePageDetailContainer" aria-label={altImage}>
      <Link className="linkPageDetail" to={linkTo}>
        <div className="tileDetailTitle">{titre}</div>
        <img className="tileDetailImage" src={mainImage} alt={altImage}></img>
      </Link>
    </Container>
  );
};

export default TilePageDetail;
