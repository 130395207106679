import React, { useState } from "react";
import "./ClicableMenuComponent.css"; // Ajoutez ce fichier CSS pour les animations
import { Link } from "react-router-dom";
import LinksEnum from "../routes/routesReference";

const ClicableMenuComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flexMenuItem">
      <div className="main-text" onClick={handleClick}>
        AMENAGEMENTS <br/> ET TERRASSES
      </div>
      <div className={`extra-texts ${isOpen ? "open" : ""}`}>
        <Link
          onClick={handleClick}
          className="extra-text"
          to={LinksEnum.AmenagementsInterieurs}
        >
          AMENAGEMENTS INTERIEURS
        </Link>
        <Link
          onClick={handleClick}
          className="extra-text"
          to={LinksEnum.AmenagementsExterieurs}
        >
          AMENAGEMENTS EXTERIEURS <br/> ET TERRASSES
        </Link>
      </div>
    </div>
  );
};

export default ClicableMenuComponent;
