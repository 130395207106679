import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import MainPic from "../img/PagesVertes/FabricationsSpecifiques/Fabrications_specifiques.jpg";
import fronton from "../img/PagesVertes/FabricationsSpecifiques/Fronton_façade.jpg";
import objetBois from "../img/PagesVertes/FabricationsSpecifiques/objet_en_bois.png";
import presentoir from "../img/PagesVertes/FabricationsSpecifiques/Présentoir_en_bois.png";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import { tileAccessoiresProps } from "../propsTiles/propsTiles";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 30%;
  }

  .boisContainer img {
    width: 100%;
  }

  .imageAlu {
    max-width: 50%;
  }
  .escalierImage {
    max-width: 75%;
  }
`;

const amenagementExterieurContent: React.ReactNode = (
  <>
    <p>
      Nous proposons des bois de coffrage, bois de qualité charpente ou
      menuiserie, prototypage en bois dur (Chêne, Hêtre, Bois exotique),
      panneaux en bois petits et grands formats, etc.
    </p>
    <p>
      Nos ateliers sont capables de travailler l'ensemble de nos produits afin
      de répondre à vos besoins les plus spécifiques :
    </p>
    <p>
      Fabrication de frontons de façades, de prototypes en tous genres (pour les
      industries du transport, du textile ou pour la logistique).
    </p>
    <h1>MANNEQUINS ET ARRETS - BOIS</h1>
    <RowBois>
      <div className="boisContainer">
        <img src={fronton} alt="" />
      </div>
      <div className="boisContainer">
        <img src={presentoir} alt="" />
      </div>
      <div className="boisContainer">
        <img src={objetBois} alt="" />
      </div>
    </RowBois>
    <p>
      Découvrez tous nos accessoires sélectionnés spécialement par nos soins
      pour leur qualité
    </p>
    <TilePageDetail {...tileAccessoiresProps} />
  </>
);

export const propsFabricationSpecifique: PageVerteCoreProps = {
  content: amenagementExterieurContent,
  imageTitre: MainPic,
  titre: "FABRICATION SPÉCIFIQUES",
};
