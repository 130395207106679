import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import MainPic from "../img/PagesVertes/FabricationCoffrage/fabricationCoffrage.jpg";
import chantier from "../img/PagesVertes/FabricationCoffrage/escalier_colimaçon.jpg";
import escalier from "../img/PagesVertes/FabricationCoffrage/escalier.jpg";
import coffrageBois from "../img/PagesVertes/FabricationCoffrage/coffrage_bois.png";
import coffrageAlu from "../img/PagesVertes/FabricationCoffrage/coffrage_aluminium.jpg";
import { styled } from "styled-components";
import { RoundedGreenContainer } from "./commonComponents";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  gap: 2em;
  margin-bottom: 2em;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 30%;
  }

  .imageAlu {
    max-width: 50%;
  }
  .escalierImage {
    max-width: 75%;
  }
`;

const AmenagementExterieursDiv = styled.div`
  .lightTitle {
    font-size: 1.4em;
    color: #c8a581;
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: center;
  }

  h2 {
    font-size: 1.2em;
  }

  p {
    margin-top: 1em;
    line-height: 1.8em;
    margin-bottom: 1em;
  }
`;

const amenagementExterieurContent: React.ReactNode = (
  <>
    <AmenagementExterieursDiv>
      <p>
        Depuis 1948, nous sommes spécialistes de la fabrication de coffrages et
        de mannequins en bois et en aluminium. Notre atelier de 3500 m² nous
        permet de fabriquer vos besoins en coffrages et mannequins dans des
        courts délais.
      </p>
      <h1 className="lightTitle">MANNEQUINS ET ARRETS - BOIS</h1>
      <RowBois>
        <div className="boisContainer">
          <RoundedGreenContainer>
            <h1>AVANTAGES</h1>
            <p>
              Prix avantageux <br />
              Sur mesure : tous besoins réalisables <br />
              Largeur de voile, dimensions, fruit, … <br />
              Joints, chanfreins, feuillure, clef, … <br />
              Géométrie : angulaire, courbe, … <br />
              Réutilisations jusqu’à 10 fois
            </p>
          </RoundedGreenContainer>
        </div>
        <div className="boisContainer">
          <h2>Utilisation Recommandée </h2>
          <p>
            Stockage au sec : hors sol et sous bâche au minimum <br />
            Aimantation chantier pour le coulage <br />
            Huile de décoffrage : systématique, application juste avant coulage
            sur surface nettoyée
            <br /> Décoffrage soigné <br />
            Zones de frappe prévues uniquement <br /> Nettoyage surface
            coffrante avec outil non saillant
          </p>
        </div>
      </RowBois>
      <RowBois>
        <img src={coffrageBois} alt="" />
      </RowBois>
      <h1 className="lightTitle">MANNEQUINS ET ARRETS - ALUMINIUM</h1>
      <RowBois>
        <div className="boisContainer">
          <RoundedGreenContainer>
            <h1>Avantages</h1>
            <p>
              Délai de fabrication court <br /> Qualité de finition <br />{" "}
              Solidité et Stabilité Réutilisations très nombreuses
            </p>
          </RoundedGreenContainer>
        </div>
        <div className="boisContainer ">
          <img className="imageAlu" src={coffrageAlu} alt=""></img>
        </div>
        <div className="boisContainer">
          <h2>Utilisation Recommandée</h2>
          <p>
            Stockage hors sol <br /> Aimantation chantier pour le coulage <br />
            Huile de décoffrage : systématique, application juste avant coulage
            sur surface nettoyée <br /> Décoffrage soigné <br /> Zones de frappe
            prévues uniquement <br /> Nettoyage surface coffrant avec outil non
            saillant
          </p>
        </div>
      </RowBois>
      <RowBois>
        <div className="boisContainer">
          <h2>Arrêts Aluminium</h2>
          <p>
            Voiles 16, 18, 20, 24, 25cm <br />
            Crochet et capot métal <br />
            Joints étanchéité <br />
            Aimantation possible
          </p>
        </div>
        <div className="boisContainer">
          <h2>Mannequins Aluminium</h2>
          <p>
            Voiles 16, 18, 20, 24, 25, 30cm <br />
            Fruit 3 % ou 5 % <br />
            Joints étanchéité <br />
            Renforts Aluminium ou Bois <br />
            Aimantation possible
          </p>
        </div>
      </RowBois>
      <h1 className="lightTitle">COFFRAGES SPECIFIQUES</h1>
      <RowBois>
        <div className="boisContainer">
          <h2>Possibilités très larges</h2>
          <p>
            Voiles, Poteaux, Dalles, Escaliers, Gradins, Piles, <br /> Ouvrages
            architecturaux, … <br /> Toutes géométries complexes : angles,
            courbes, cônes, … <br /> Livraison en kit ou monté
          </p>
        </div>
        <div className="boisContainer">
          <h2>Accompagnement complet</h2>
          <p>
            Disponibilité <br /> Etudes de faisabilité <br /> Adaptation aux
            méthodes de coulage souhaitées <br /> Force de proposition <br />
            Remise de prix rapide
          </p>
        </div>
      </RowBois>
      <RowBois>
        <img className="escalierImage" src={escalier} alt=""></img>
      </RowBois>
      <RowBois>
        <img className="escalierImage" src={chantier} alt=""></img>
      </RowBois>
    </AmenagementExterieursDiv>
  </>
);

export const propsFabricationCoffrage: PageVerteCoreProps = {
  content: amenagementExterieurContent,
  imageTitre: MainPic,
  titre: "FABRICATION DE COFFRAGES ET MANNEQUINS",
};
