import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./SlidableMenu.css";
import { Link } from "react-router-dom";
import LinksEnum from "../routes/routesReference";

function SlidableMenu() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isOpen, setIsOpen] = useState(false);
  const [isBoisBrutMenuOpen, setIsBoisBrutMenuOpen] = useState(false);
  const [isPanneauxMenuOpen, setIsPanneauxMenuOpen] = useState(false);
  const [isFabricationMenuOpen, setIsFabricationMenuOpen] = useState(false);
  const [isRevetementMenuOpen, setIsRevetementMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleBoisBrutSubMenu = () => {
    setIsBoisBrutMenuOpen(!isBoisBrutMenuOpen);
  };

  const toggleIsPanneauxOpen = () => {
    setIsPanneauxMenuOpen(!isPanneauxMenuOpen);
    isBoisBrutMenuOpen && setIsBoisBrutMenuOpen(!isBoisBrutMenuOpen);
  };

  const toggleIsFabricationOpen = () => {
    setIsFabricationMenuOpen(!isFabricationMenuOpen);
    isPanneauxMenuOpen && setIsPanneauxMenuOpen(!isPanneauxMenuOpen);
    isRevetementMenuOpen && setIsRevetementMenuOpen(!isRevetementMenuOpen);
    isBoisBrutMenuOpen && setIsBoisBrutMenuOpen(!isBoisBrutMenuOpen);
  };

  const toggleIsRevetementMenuOpen = () => {
    setIsRevetementMenuOpen(!isRevetementMenuOpen);
    isBoisBrutMenuOpen && setIsBoisBrutMenuOpen(!isBoisBrutMenuOpen);
    isFabricationMenuOpen && setIsFabricationMenuOpen(!isFabricationMenuOpen);
    isPanneauxMenuOpen && setIsPanneauxMenuOpen(!isPanneauxMenuOpen);
  };
  return (
    <>
      <div className={`menu-container`}>
        <div onClick={toggleMenu} className="menu-button">
          <div className={`hoverCursor burger-icon ${isOpen ? "open" : ""}`}>
            <div className={`bar bar1 `}></div>
            <div className={`bar bar2 `}></div>
            <div className={`bar bar3 `}></div>
          </div>
          <div className="menuName">{!isMobile && "MENU"}</div>
        </div>
        <div className={`menu ${isOpen ? "open" : ""}`}>
          <ul className="hoverCursor mainMenu">
            <li
              className={`hoverCursor menuLink`}
              onClick={toggleBoisBrutSubMenu}
            >
              Bois Bruts
            </li>
            {isBoisBrutMenuOpen && (
              <ul className="subMenu">
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.BoisDeCoffrage}
                  >
                    Bois de coffrage
                  </Link>
                </li>
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.BoisDeCharpente}
                  >
                    Bois de charpente
                  </Link>
                </li>
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.BoisPourIndustrie}
                  >
                    Bois pour l'industrie
                  </Link>
                </li>
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.BoisPourAmenagementsInterieurs}
                  >
                    Bois pour aménagement intérieur
                  </Link>
                </li>
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.BoisPourAmenagementsExterieurs}
                  >
                    Bois pour aménagement extérieur
                  </Link>
                </li>
              </ul>
            )}
            <li className={`hoverCursor menuLink`} onClick={toggleMenu}>
              <Link
                onClick={toggleMenu}
                className={`menuLink`}
                to={LinksEnum.UsinagesSpecifiques}
              >
                Bois et panneaux transformés
              </Link>
            </li>
            <li
              className={`hoverCursor menuLink`}
              onClick={toggleIsPanneauxOpen}
            >
              Panneaux
            </li>
            {isPanneauxMenuOpen && (
              <ul className="subMenu">
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.PanneauxFilmes}
                  >
                    Panneaux Filmés
                  </Link>
                </li>
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.DallesEtPanneauxNonFilmes}
                  >
                    Dalles et panneaux non filmés
                  </Link>
                </li>
              </ul>
            )}
            <li
              className={`hoverCursor menuLink`}
              onClick={toggleIsFabricationOpen}
            >
              Fabrications
            </li>
            {isFabricationMenuOpen && (
              <ul className="subMenu">
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.FabricationCoffrageEtMannequins}
                  >
                    Fabrication coffrages et mannequins
                  </Link>
                </li>
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.FabricationsSpecifiques}
                  >
                    Fabrications spécifiques (frontons, présentoirs...)
                  </Link>
                </li>
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.AmenagementsExterieurs}
                  >
                    Fabrication meubles et aménagements intérieurs et extérieurs
                  </Link>
                </li>
              </ul>
            )}
            <li
              className={`hoverCursor menuLink`}
              onClick={toggleIsRevetementMenuOpen}
            >
              Revêtements de sol <br/>et mur
            </li>
            {isRevetementMenuOpen && (
              <ul className="subMenu">
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.Parquet}
                  >
                    Parquet
                  </Link>
                </li>
                <li className="menuLink">
                  <Link
                    onClick={toggleMenu}
                    className={`subMenuLink`}
                    to={LinksEnum.TerrasseBardage}
                  >
                    Terrasse et Bardage
                  </Link>
                </li>
              </ul>
            )}
            <li className={`hoverCursor menuLink`} onClick={toggleMenu}>
              <Link
                onClick={toggleMenu}
                className={`menuLink`}
                to={LinksEnum.Accessoires}
              >
                Accessoires
              </Link>
            </li>
            <li className={`hoverCursor menuLink`} onClick={toggleMenu}>
              <Link
                onClick={toggleMenu}
                className={`menuLink`}
                to="/nosrealisations-meubles-en-bois-sur-mesure"
              >
                FAQ
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SlidableMenu;
