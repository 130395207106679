import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import mainPic from "../img/PagesVertes/Parquet/Terrasse_et_bardages.jpg";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import { tileBoisPourAmenagementExterieursProps } from "../propsTiles/propsTiles";
import { RoundedGreenContainer } from "./commonComponents";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;


const ListeEssence = styled.div``;
const industrieContent: React.ReactNode = (
  <>
    <p>
      Nous sommes une entreprise spécialisée dans la fourniture de bois de la
      meilleure des qualités notamment pour terrasses et bardages. Nous
      proposons une large gamme de bois adaptés à tous vos projets extérieurs,
      combinant esthétisme, durabilité et respect de l'environnement.
    </p>
    <p>
      Un agrandissement de terrasse ou de bardage à effectuer dans quelques
      années ? Nous pourrons vous proposer exactement le même produit que celui
      déjà acheté chez nous.
    </p>
    <h1>NOS TYPES DE BOIS POUR TERRASSE</h1>
    <h2>BOIS EXOTIQUES</h2>
    <p>
      Reconnus pour leur durabilité et leur résistance naturelle aux intempéries
      et aux insectes, les bois exotiques sont parfaits pour des terrasses
      durables.
    </p>
    <p>Les essences que nous vendons le plus fréquemment sont :</p>
    <ListeEssence>
      <p>
        <b>Ipé</b> : D'une grande densité et résistance, l'Ipé offre une couleur
        brune et une excellente longévité.
      </p>
      <p>
        <b>Padouk</b> : Connu pour sa résistance aux intempéries et sa teinte
        rouge devenant gris clair en un temps record, le Padouk est la parfaite
        alternative à l'Ipé.
      </p>
      <p>
        <b>Jatoba</b> : Bois brésilien reconnaissable par son côté flammé sans
        pareil et sa très grande dureté, c'est un excellent rapport qualité
        prix.
      </p>
      <p>
        <b>Cumaru</b> : Un bois dur et résistant, avec des nuances de brun
        rougeâtre.
      </p>
      <p>
        Nous fournissons également de nombreuses autres essences exotiques :
        Sipo, Itauba, Massaranduba…
      </p>
    </ListeEssence>
    <h2>BOIS RÉSINEUX</h2>
    <p>Les bois résineux offrent un très bon rapport qualité/prix</p>
    <ListeEssence>
      <p>
        <b>Pin traité autoclave</b> : Soumis à un traitement autoclave, le pin,
        avec sa teinte verte, offre une bonne résistance aux intempéries.
      </p>
      <p>
        <b>Mélèze</b> : Naturellement résistant aux intempéries, il présente une
        belle teinte dorée et se patine avec le temps.
      </p>
      <p>
        <b>Douglas</b> : Cousin du Mélèze, il présente les même propriétés tout
        en étant plus facile à sourcer. Il présente une belle teinte légèrement
        rosée à la pose, et se patine avec le temps.
      </p>
      <p>
        <b>Cumaru</b> : Un bois dur et résistant, avec des nuances de brun
        rougeâtre.
      </p>
      <p>
        Nous fournissons également de nombreuses autres essences exotiques :
        Sipo, Itauba, Massaranduba…
      </p>
    </ListeEssence>
    <h1>NOS TYPES DE BOIS POUR BARDAGE :</h1>
    <p>
      Le bardage en bois est une solution esthétique et isolante pour habiller
      vos façades extérieures. Nous proposons des bois de bardage adaptés à
      divers styles architecturaux et exigences climatiques.
    </p>
    <h2>DIFFÉRENTS TYPES DE BOIS</h2>
    <ListeEssence>
      <p>
        <b>Red Cedar</b> : Léger et résistant aux intempéries, il offre une
        belle teinte rougeâtre qui se grise naturellement avec le temps. Ce bois
        est spécifiquement utilisé pour le bardage.
      </p>
      <p>
        <b>Padouk, Ipé, Jatoba… </b> : L'ensemble des essences exotiques que
        nous possédons dans notre parc de 15 000m² peuvent être utilisés en
        bardage.
      </p>
      <p>
        <b>Épicéa traité</b> : Un bois léger et économique, traité pour résister
        aux intempéries et aux insectes.
      </p>
      <p>
        <b>Douglas </b> : Naturellement durable et offrant une belle teinte
        rosée, il est souvent utilisé non traité pour une patine naturelle.
      </p>
      <p>
        <b>Cumaru</b> : Un bois dur et résistant, avec des nuances de brun
        rougeâtre.
      </p>
      <p>
        <b>Mélèze </b> : Comme son cousin le Douglas, le Mélèze offre de bonnes
        propriétés de durabilité.
      </p>
      <p>
        <b>Frêne thermotraité </b> : Offre une grande stabilité dimensionnelle
        et une belle teinte brune.
      </p>
      <p>
        <b>Pin thermotraité </b> : Plus résistant aux moisissures et insectes,
        avec une couleur brun doré.
      </p>
    </ListeEssence>
    <h1>DIFFÉRENTES FORMES</h1>
    <ListeEssence>
      <p>
        <b>Bardage en clin </b> : soit des lames à emboitement qui ne laissent
        aucun vide.
      </p>
      <p>
        <b>Bardage avec superposition des lames </b> : Nous pouvons à la demande
        usiner des feuillures et des biais dans vos bois pour un profil
        spécifique très américain.
      </p>
      <p>
        <b>Bardage en claire-voie </b> : soit des lames ou des tasseaux espacés
        les un des autres. Il permet la plus grande créativité : vous maitrisez
        la totalité des paramètres : sections des bois pouvant être multiples
        sur un même pan de mur avec une alternative de bois larges / étroits,
        fins / épais, espacement des bois, etc.
      </p>
      <p>
        <b>Bardage en faux claire-voie </b> : soit des lames pleines striées
        pour donner l'illusion d'un vide.
      </p>
    </ListeEssence>
    <RoundedGreenContainer>
      <h1>AVANTAGES DE NOS BOIS DE TERRASSE ET BARDAGE</h1>
      <RowBois>
        <div className="boisContainer">
          <h2>Durabilité : </h2>
          <p>
            Sélectionnés pour leur résistance naturelle ou traités pour une
            longévité accrue.
          </p>
        </div>
        <div className="boisContainer">
          <h2>Esthétisme : </h2>
          <p>
            Une large variété de teintes et de textures pour s’adapter à tous
            les styles.
          </p>
        </div>
        <div className="boisContainer">
          <h2>Facilité d'entretien :</h2>
          <p>Solutions sur mesure pour répondre à vos besoins spécifiques.</p>
        </div>
        <div className="boisContainer">
          <h2>Écologique : </h2>
          <p>Bois issus de forêts gérées durablement, certifiés FSC et PEFC.</p>
        </div>
      </RowBois>
    </RoundedGreenContainer>
    <p>Vous êtes à la recherche de bois pour réaliser vos aménagements extérieurs ? (clôtures, terrains de pétanque, etc) 
    Consultez notre rubrique dédiée :</p>
    <TilePageDetail {...tileBoisPourAmenagementExterieursProps}/>
  </>
);

export const propsForTerrasseEtBardage: PageVerteCoreProps = {
  content: industrieContent,
  imageTitre: mainPic,
  titre: "TERRASSE ET BARDAGE",
};
