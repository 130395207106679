import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import MainPic from "../img/PagesVertes/Amenagements/Interieur/Bois_pour_aménagements_intérieurs.jpg";
import { styled } from "styled-components";
import TilePageDetail from "../components/TilePageDetail";
import {
  tileAccessoiresProps,
  tileFabricationMeublesEtAmenagementsProps,
} from "../propsTiles/propsTiles";
import { RoundedGreenContainer } from "./commonComponents";

const RowBois = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boisContainer {
    display: flex;
    flex-direction: column;
    align-items:center;
  }
`;


const amenagementInterieurContent: React.ReactNode = (
  <>
    <p>
      Nous offrons une large gamme de produits pour embellir vos espaces de vie,
      allant des plinthes et moulures aux bois pour claustras et autres
      décorations. Forts de notre expertise et de notre engagement qualité, nous
      fournissons des solutions adaptées à tous vos besoins en aménagement
      intérieur.
    </p>

    <h1>NOS PRODUITS</h1>
    <RowBois>
      <RowBois>
        <div className="boisContainer">
          <h2>Plinthes</h2>
          <p>
            Les plinthes en bois ajoutent une touche finale élégante à vos
            pièces tout en protégeant les bas de murs.
          </p>
        </div>
        <div className="boisContainer">
          <h2>Moulures</h2>
          <p>
            Les moulures en bois apportent du caractère et du style à vos
            intérieurs, idéales pour des finitions sophistiquées.
          </p>
        </div>
        <div className="boisContainer">
          <h2>Bois pour claustras</h2>
          <p>
            Les claustras en bois sont des éléments décoratifs qui permettent de
            structurer l’espace tout en ajoutant une touche esthétique.
          </p>
          <p>
            En bois massif, ils apportent robustesse et esthétique naturelle
            pour des séparations élégantes.
          </p>
        </div>
      </RowBois>
    </RowBois>
    <RowBois></RowBois>
    <RowBois>
      <div className="boisContainer">
        <h2>Bois pour habillage mural</h2>
        <p>
          Créez des décorations murales uniques avec nos bois pour habillage
          mural.
        </p>
        <p>
          Lambris en bois : Disponible en différentes essences et finitions,
          pour un charme rustique ou contemporain.
        </p>
        <p>Panneaux décoratifs : Pour une touche moderne et artistique.</p>
        <p>Bois récupéré : Pour un look authentique et écologique.</p>
      </div>
      <div className="boisContainer">
        <h2>Bois pour menuiserie intérieure</h2>
        <p>
          Pour tous vos projets de menuiserie intérieure, nous proposons des
          bois de qualité adaptés à vos besoins.
        </p>
        <p>
          Chêne massif : Pour des meubles et des structures solides et durables.
        </p>
        <p>
          Sapin du nord : Pour une stabilité dimensionnelle et une bonne
          résistance.
        </p>
        <p>Bois exotique : Pour des pièces uniques et élégantes.</p>
      </div>
    </RowBois>
    <RoundedGreenContainer>
      <h1>AVANTAGE DE NOS PRODUITS EN BOIS</h1>
      <h2>Qualité supérieure : </h2>
      <p>Nos bois sont sélectionnés pour leur robustesse et leur esthétisme.</p>
      <h2>Personnalisation : </h2>
      <p>
        Large choix de styles, essences et finitions pour s’adapter à tous vos
        projets.
      </p>
      <h2>Durabilité :</h2>
      <p>
        Durabilité : Bois issus de forêts gérées durablement, certifiés FSC et
        PEFC.
      </p>
      <h2>Facilité d’installation : </h2>
      <p>Produits conçus pour une installation simple et rapide.</p>
    </RoundedGreenContainer>
    <h1>Application :</h1>
    <p>
      Nos bois pour aménagements intérieurs sont parfaits pour divers projets,
      tels que :
    </p>
    <RowBois>
      <div className="boisContainer">
        <h2>Renovation de pièces</h2>
        <p>
          Ajoutez des plinthes, moulures et habillages muraux pour un look
          renouvelé.
        </p>
      </div>
      <div className="boisContainer">
        <h2>Décoration intérieure : </h2>
        <p>
          Utilisez des claustras et panneaux décoratifs pour structurer et
          embellir vos espaces.
        </p>
      </div>
      <div className="boisContainer">
        <h2>Menuiserie sur mesure : </h2>
        <p>Créez des meubles et des éléments décoratifs uniques en bois.</p>
      </div>
    </RowBois>
    <p>
      Envie de solutions sur mesure, meubles et aménagements intérieurs ?
      Visitez notre site Maison Charlet !
    </p>
    <TilePageDetail {...tileFabricationMeublesEtAmenagementsProps} />
    <p>
      Découvrez tous nos accessoires sélectionnés spécialement par nos soins
      pour leur qualité
    </p>
    <TilePageDetail {...tileAccessoiresProps} />
  </>
);

export const propsForAmenagementInterieur: PageVerteCoreProps = {
  content: amenagementInterieurContent,
  imageTitre: MainPic,
  titre: "BOIS POUR AMÉNAGEMENT INTÉRIEURS",
};
