import React, { useEffect, useState, useRef } from 'react';
import './ParticleFall.css'; // Assurez-vous d'avoir créé ce fichier CSS

interface Particle {
  x: number;
  color: string;
}

const ParticleFall: React.FC = () => {
  const [particles, setParticles] = useState<Particle[]>([]);
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Utilisation d'un ref pour stocker l'intervalle
  const createParticle = () => {
    const colors = [
      '#8B4513', '#8B5A2B', '#8B3E2B', '#8B6F4B', '#8B7A3C', // Variations de brun
      '#FFFFFF', '#FDFDFD', '#FBFBFB', '#F7F7F7', '#EFEFEF' // Variations de blanc
    ];
    const x = Math.random() * 10; // Position aléatoire sur l'axe X
    const color = colors[Math.floor(Math.random() * colors.length)];
    setParticles((prev) => [...prev, { x, color }]);
  };

  useEffect(() => {
    const rate = 30; // Définir le taux minimum de production (en ms)
    
    intervalRef.current = setInterval(() => {
      createParticle();
    }, rate);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <div className="particle-container">
      {particles.map((particle, index) => (
        <div
          key={index}
          className="particle"
          style={{
            left: `${particle.x}%`,
            backgroundColor: particle.color,
            animation: 'fall 3s linear forwards',
          }}
        />
      ))}
    </div>
  );
};

export default ParticleFall;
