import React from "react";
import { PageVerteCoreProps } from "../components/PageVerteCore";
import BoisDeCoffrage from "../img/PagesVertes/Coffrage/Bois_de_coffrage.jpg";
import ScieAndCertif from "../components/ScieAndCertif";
import { styled } from "styled-components";
import boisCoffrage from "../img/PagesVertes/Coffrage/bois_coffrage_charlet.jpg";
import H20 from "../img/PagesVertes/Coffrage/H20_bois_charlet.jpg";
import Rouge from "../img/PagesVertes/Coffrage/Planches de sécurité.jpg";
import Piquet from "../img/PagesVertes/Coffrage/piquets_charlet_bois.jpg";
import TilePageDetail from "../components/TilePageDetail";
import {
  tileAccessoiresProps,
  tileUsinagesSpecifiquesProps,
} from "../propsTiles/propsTiles";

const StyledTable = styled.table`
  table {
    width: 100%;
  }

  table,
  th,
  td {
    border: 1px solid black; /* Lignes noires pour le tableau, les cellules et les headers */
  }

  th {
    font-weight: bold; /* Header en gras */
    padding: 10px; /* Espacement pour un peu plus de lisibilité */
  }

  td {
    padding: 10px; /* Espacement pour les cellules du corps du tableau */
  }
`;

const ImageBoisCoffrage = styled.img`
  width: 100%;
`;

const ImageElementDetail = styled.img`
  width: 100%;
`;

const DivRowImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 5em;
  align-items: center;
  justify-content: center;
`;

const StyledDiv = styled.div`
  table {
    width: 80%;
  }

  @media (min-width: 768px) {
    th {
      font-size: 1em;
    }
  }

  @media (max-width: 768px) {
    th {
      font-size: 0.8em;
    }
  }
`;

const DivImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    width: 33%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const coffrageContent: React.ReactNode = (
  <>
    <p>
      Les bois de coffrage sont les bois utilisés pour les structures ou
      charpentes temporaires utilisées dans des projets de construction.
    </p>
    <ScieAndCertif></ScieAndCertif>
    <p>
      Les sections et dénominations standards en bois de coffrage sont les
      suivantes :
    </p>
    <StyledDiv>
      <StyledTable>
        <tr>
          <th>DÉNOMINATION</th>
          <th>ÉPAISSEURS</th>
          <th>LARGEURS</th>
        </tr>
        <tr>
          <td>VOLIGE</td>
          <td>18</td>
          <td>100</td>
        </tr>
        <tr>
          <td>PLANCHE</td>
          <td>27</td>
          <td>TL, 100, 150, 200, 250</td>
        </tr>
        <tr>
          <td>PLANCHE</td>
          <td>32</td>
          <td>150, 175</td>
        </tr>
        <tr>
          <td>PLANCHE</td>
          <td>40</td>
          <td>225, 250</td>
        </tr>
        <tr>
          <td>CHEVRON</td>
          <td>65</td>
          <td>80</td>
        </tr>
        <tr>
          <td>BASTAING</td>
          <td>65</td>
          <td>180</td>
        </tr>
        <tr>
          <td>MADRIER</td>
          <td>80</td>
          <td>230</td>
        </tr>
      </StyledTable>
    </StyledDiv>
    <ImageBoisCoffrage
      src={boisCoffrage}
      alt="Bois de Coffrage Charlet Bois"
    ></ImageBoisCoffrage>
    <h2>Nous proposons également à la vente :</h2>
    <DivRowImages>
      <DivImageContainer>
        <h2>Profil H20</h2>
        <p>dans une gamme de longueurs</p>
        <ImageElementDetail src={H20} alt=""></ImageElementDetail>
      </DivImageContainer>
      <DivImageContainer>
        <h2>Planches de sécurité</h2>
        <p>Planches rouges</p>
        <ImageElementDetail src={Rouge} alt=""></ImageElementDetail>
      </DivImageContainer>
      <DivImageContainer>
        <h2>Piquets</h2>
        <p>En 40mm ainsi qu’en 60mm</p>
        <ImageElementDetail src={Piquet} alt=""></ImageElementDetail>
      </DivImageContainer>
    </DivRowImages>
    <DivRowImages>
      <DivImageContainer>
        <p>
          Chacune de ces sections peut être retravaillée de façon à répondre à
          vos besoins plus spécifiques
        </p>
        <TilePageDetail {...tileUsinagesSpecifiquesProps}></TilePageDetail>
      </DivImageContainer>
      <DivImageContainer>
        <p>
          Découvrez tous nos accéssoires sélectionnés spécialement par nos soins
          pour leur qualité
        </p>
        <TilePageDetail {...tileAccessoiresProps}></TilePageDetail>
      </DivImageContainer>
    </DivRowImages>
  </>
);

export const propsForBoisDeCoffrage: PageVerteCoreProps = {
  content: coffrageContent,
  imageTitre: BoisDeCoffrage,
  titre: "BOIS DE COFFRAGE",
};
