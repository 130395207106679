import { useState } from "react";

const ContactElement = () => {
  const [status, setStatus] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
  
    // Mettre à jour uniquement le champ "detailDemande"
    setFormData((prevFormData) => ({
      ...prevFormData, // Conserve toutes les autres propriétés
      detailDemande: value // Met à jour "detailDemande" uniquement
    }));
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    detailDemande: "",
    agreeToTerms: false,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus("Envoi en cours...");

    try {
      const response = await fetch("/.netlify/functions/send-mail", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setStatus("Message envoyé avec succès!");
      } else {
        setStatus("Erreur lors de l'envoi du message.");
      }
    } catch (error) {
      setStatus("Erreur réseau.");
    }
    console.log(formData);
  };

  return (
    <>
      {/* Deuxième div : Formulaire */}
      <div>
        <form onSubmit={handleSubmit}>
          <div className="containingDiv">
            <label htmlFor="firstName">Nom</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              style={{
                padding: "8px",
                marginBottom: "10px",
              }}
            />
          </div>
          <div className="containingDiv">
            <label htmlFor="lastName">Prénom</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              style={{
                padding: "8px",
                marginBottom: "10px",
              }}
            />
          </div>
          <div className="containingDiv">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={{
                padding: "8px",
                marginBottom: "10px",
              }}
            />
          </div>
          <div className="containingDiv">
            <label htmlFor="phone">Téléphone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              style={{
                padding: "8px",
                marginBottom: "10px",
              }}
            />
          </div>
          <div className="containingDiv">
            <label htmlFor="detailDemande">Détail de votre demande</label>
            <textarea
              id="detailDemande"
              name="detailDemande"
              value={formData.detailDemande}
              onChange={handleChangeTextArea}
              style={{
                padding: "8px",
                marginBottom: "10px",
                height: "8em"   
              }}
            />
          </div>
          <div className="rowCheckbox containingDiv">
            <input
              type="checkbox"
              id="agreeToTerms"
              name="agreeToTerms"
              checked={formData.agreeToTerms}
              onChange={handleChange}
              required
            />
            <label htmlFor="agreeToTerms">
              J'accepte les termes et conditions
            </label>
          </div>
          <div style={{ marginTop: "20px" }}>
            <button type="submit">Envoyer</button>
          </div>
        </form>
        {status && <p>{status}</p>}
      </div>
    </>
  );
};

export default ContactElement;
