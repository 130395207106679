import React, { ReactNode } from "react";
import styled from "styled-components";
import Header from "./Header";
import "./PageResume.css";
import TilePageDetail, { TileProps } from "./TilePageDetail";
import { TitleComponent } from "./TitleComponent";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

export interface PageResumeProps {
  titre: string;
  imageTitre: string;
  firstParagraph: string;
  secondParagraph: string | ReactNode;
  thirdParagraph: string;
  tile1: TileProps;
  tile2: TileProps;
  tile3: TileProps;
  tile4: TileProps;
  tile5: TileProps;
  tile6?: TileProps;
  bottomImage: string;
}

const SecondPartOfPageResumeComponent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 3em;
`;

const BottomImageComponent = styled.div<{ imageBottom: string }>`
  background-image: url(${(props) => props.imageBottom});
  background-size: cover;
  background-position: center;
  width: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const PageResume: React.FC<PageResumeProps> = ({
  titre,
  imageTitre,
  firstParagraph,
  bottomImage,
  secondParagraph,
  thirdParagraph,
  tile1,
  tile2,
  tile3,
  tile4,
  tile5,
  tile6,
}) => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="mainPageResumeContainer">
        <TitleComponent imageTitre={imageTitre}>
          <div className="titleElement">{titre}</div>
        </TitleComponent>
        <div className="mainContent">
          <p className="firstParagraph">{firstParagraph}</p>
          <div className="firstRowOfTiles">
            <TilePageDetail
              altImage={tile1.altImage}
              mainImage={tile1.mainImage}
              titre={tile1.titre}
              linkTo={tile1.linkTo}
            ></TilePageDetail>
            <TilePageDetail
              altImage={tile2.altImage}
              mainImage={tile2.mainImage}
              titre={tile2.titre}
              linkTo={tile2.linkTo}
            ></TilePageDetail>
            <TilePageDetail
              altImage={tile3.altImage}
              mainImage={tile3.mainImage}
              titre={tile3.titre}
              linkTo={tile3.linkTo}
            ></TilePageDetail>
          </div>
          <SecondPartOfPageResumeComponent>
            <div className="flexColumnForPageResume">
              <p>{secondParagraph}</p>
              <div className="rowForSecondTiles">
                <TilePageDetail
                  altImage={tile4.altImage}
                  mainImage={tile4.mainImage}
                  titre={tile4.titre}
                  linkTo={tile4.linkTo}
                ></TilePageDetail>
                {tile6 && (
                  <TilePageDetail
                    altImage={tile6.altImage}
                    mainImage={tile6.mainImage}
                    titre={tile6.titre}
                    linkTo={tile6.linkTo}
                  ></TilePageDetail>
                )}
              </div>
              <p>{thirdParagraph}</p>
              <TilePageDetail
                altImage={tile5.altImage}
                mainImage={tile5.mainImage}
                titre={tile5.titre}
                linkTo={tile5.linkTo}
              ></TilePageDetail>
            </div>
            <BottomImageComponent
              className="bottomImage"
              imageBottom={bottomImage}
            ></BottomImageComponent>
          </SecondPartOfPageResumeComponent>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PageResume;
