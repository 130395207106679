import { PageResumeProps } from "../components/PageResume";
import tileImage1 from "../img/TuilesPagesRouges/Charpente/Couverture_charpente_couverture_ossature.jpg";
import bottomImage from "../img/TuilesPagesRouges/Charpente/Balcons_touquet.jpg";

import { tileAccessoiresProps, tileBoisDeCharpenteCouvertureOssature, tileDallesProps, tileFabricationsSpecifiquesProps, tileOssatureBois, tileUsinagesSpecifiquesProps } from "../propsTiles/propsTiles";

export const charpenteProps: PageResumeProps = {
  titre: "CHARPENTE, COUVERTURE ET OSSATURE",
  imageTitre: tileImage1,
  firstParagraph: `Nous sommes spécialistes du négoce de bois et de panneaux servant à la construction de charpentes ou d'extensions en ossature bois avec un stock de plus de 15 000 m², situé près de Lille.

Nous proposons des bois d'ossature, bois de charpente, panneaux OSB, dalles OSB, panneaux de bois petits et grands formats, etc. Tous nos bois peuvent être traités classe II par nos soins via notre cuve de traitement.

Nos ateliers sont capables de travailler l'ensemble de nos produits afin de répondre à vos besoins les plus spécifiques : bois coupés à longueur, délignés, rabotés, moulurés, débit de panneaux, gabarits de cintrage, etc.`,
  tile1: tileBoisDeCharpenteCouvertureOssature,
  tile2: tileDallesProps,
  tile3: tileUsinagesSpecifiquesProps,
  tile4: tileFabricationsSpecifiquesProps,
  tile5: tileAccessoiresProps,
  tile6: tileOssatureBois,
  bottomImage: bottomImage,
  secondParagraph:
    "Notre société possède une grande expérience dans la fabrication d'éléments spécifiques, notamment pour les façades (frontons, balcons..) ou encore dans la fabrication de murs en ossature bois directement dans notre atelier de 3500m², situé à côté de Lille, pouvant réaliser rapidement et avec soin vos besoins.",
  thirdParagraph:
    "Nous fournissons également l'ensemble des accessoires nécessaires à toutes vos réalisations (visserie, plots, sabots, colle, etc.)",
};
